import {faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import {ParametersContext} from "../../../store/context";
import {some} from "lodash";
import {AddBySelect} from "../selectors";
import {TableSorter} from "../../../components";

export function TableInEdit({values, columns, columnsExport, fieldName, parameters, excludeIDs = [], emptyValues = null, name = null, onChange = null}) {
    const { parameters: p, updateParameters } = useContext(ParametersContext);
    useEffect(() => updateParameters({Parameters: parameters, EmptyValues: emptyValues}), [emptyValues, parameters, updateParameters]);

    const {setValue} = useFormContext();
    const [content, setContent] = useState(values);
    const [show, setShow] = useState(false);

    const onAdd = (elm) => {
        let nv = {};

        if (elm != null) {
            let n = name === null ? parameters.split('-')[0].slice(0, -2) : name;

            console.log(n);

            if (emptyValues !== null) {
                nv = {...p.e[emptyValues]};
                nv[n] = {};
                nv[n].Display = elm.label;
                nv[n].ID = elm.value;
            } else {
                nv.Display = elm.label;
                nv.ID = elm.value
            }

            if((emptyValues === null || !some(content.map(f => f[n]), nv[n])) && !excludeIDs.includes(elm.value)) content.push(nv);

            let result = [...content];
            setContent(result);
            setShow(prevState => !prevState);

            if (onChange !== null) onChange(result);
        }
    };

    const onRemove = item => {
        let result = content.filter(f => f !== item)
        setContent(result);
        if (onChange !== null) onChange(result);
    }

    const toggleShow = useCallback(() => {
        setShow(prevState => !prevState);
    }, []);

    useEffect(() => { setValue(fieldName, content); }, [fieldName, setValue, content])

    return <>
        <AddBySelect show={show} onChange={onAdd} options={p.p[parameters] ?? []}/>
        <TableSorter
            columns={columns}
            columnsExport={columnsExport ?? []}
            content={content}
            actions={[{
                onClick: onRemove, icon: faTrashAlt, title: "Supprimer"
            }]}
            addAction={toggleShow}
        />
    </>;
}
import React, {useContext, useEffect} from 'react';
import {Outlet, useLocation, useParams} from "react-router-dom";
import axios from "axios";
import {GlobalContext, AppContext} from "../../../store/context";
import {getCurrentRoute} from "../../../utils/routes";
import Student from "../../../components/utils/Student";
import Inscriptions from "./Inscriptions";

export default function Etudiant(props) {
    const {dispatch} = useContext(GlobalContext);

    const {store} = useContext(AppContext);
    const location = useLocation();
    const current = getCurrentRoute(store.authenticatedRoutes, location);

    let params = useParams();
    let EtudiantID = props.location === undefined ? params.EtudiantID : props.location.state.EtudiantID

    useEffect(() => {
        dispatch({key: "etudiant", value: {}});

        const fetch = async (EtudiantID) => {
            try {
                const {data} = await axios.get(`/Screen/1/${EtudiantID}`);
                let result = data.result.length < 2 ? data.result.shift() : data.result
                dispatch({key: "etudiant", value: result});
            } catch (e) {
                console.log(e);
                console.log(e.response);
            }
        };

        fetch(EtudiantID);
    }, [dispatch, EtudiantID])

    return (
        <>
            <Student/>

            {current.length <= 3 && <Inscriptions/>}

            <Outlet/>
        </>
    );
}

import React, {useEffect, useState} from 'react';
import {useMsal} from '@azure/msal-react';
import axios from 'axios';
import {apiScopes} from "./authConfig";
import {InteractionRequiredAuthError} from "@azure/msal-browser";
import {toast} from "react-toastify";
import updateToast from "./updateToast";
import Security from "../security";

let axiosRequest = null;
let axiosResponse = null;
let toastIDs = [];

const RequestInterceptor = ({children}) => {
    const {instance, accounts} = useMsal();
    const [account, setAccount] = useState(instance.getActiveAccount());

    // Keep account in sync with MSAL state
    useEffect(() => {
        if (accounts.length > 0 && (!account || accounts[0].homeAccountId !== account.homeAccountId)) {
            if (accounts.length > 0 && (!account || accounts[0].homeAccountId !== account.homeAccountId)) {
                const activeAccount = instance.getActiveAccount() || accounts[0];
                if(activeAccount !== account) {
                    instance.setActiveAccount(activeAccount);
                    setAccount(activeAccount);
                }
            }
        }
    }, [accounts, account, instance]);

    useEffect(() =>
    {
        if (!axiosRequest) {
            axiosRequest = axios.interceptors.request.use(async (config) => {
                // Skip auth management for public route
                if(config.url.toLowerCase().includes("/qr")) {
                    return config;
                }

                // Use this variable instead of the account directly
                // On first login, the setAccount finishes execution after RequestInterceptor is called which throws an error
                const act = account || instance.getActiveAccount();

                if (!act) {
                    console.error('No active account! Verify a user has been signed in.');
                    return config;
                }

                if (!Security.screenAccessInitialized && !config.url.includes('parameters')) {
                    config.url = "";
                    return config;
                }

                try {
                    const response = await instance.acquireTokenSilent({...apiScopes, account: account});
                    config.headers.Authorization = `Bearer ${response.accessToken}`;
                } catch(error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        try {
                            const response = await instance.acquireTokenPopup({ ...apiScopes, account });
                            config.headers.Authorization = `Bearer ${response.accessToken}`;
                        } catch (popupError) {
                            console.error('Error during token popup acquisition:', popupError);
                            throw popupError;
                        }
                    } else {
                        console.error('Error during silent token acquisition:', error);
                        throw error;
                    }
                }

                // Toastify logic for method-based notifications
                config.toastify = config.toastify ?? {};

                const method = {
                    post: "Sauvegarde",
                    POST: "Sauvegarde",
                    delete: "Suppression",
                    DELETE: "Suppression"
                };

                if (["post", "POST", "delete", "DELETE"].includes(config.method) && !config.url.includes("check") && !config.url.includes("preferencies")) {
                    toastIDs.push(toast.info(`${method[config.method]} en cours!`, {autoClose: false}));
                    config.toastify.success = config.toastify.success || { render: `${method[config.method]} réussie!` };
                    config.toastify.error = config.toastify.error || { render: `Erreur lors de la ${method[config.method].toLowerCase()}!` };
                }

                config.url = !config.url.includes(process.env.REACT_APP_API_ENDPOINT) ? `${process.env.REACT_APP_API_ENDPOINT}${config.url}` : config.url;

                return config;
            },(error) => {
                    // Handle errors that occur before the request is sent
                    console.error('Interceptor encountered an error:', error);
                    return Promise.reject(error);
                }
            );
        }

        if (!axiosResponse) {
            axiosResponse = axios.interceptors.response.use((response) => {
                // Handle successful responses
                if (response.config.toastify?.success && toastIDs.length) {
                    updateToast({
                        type: toast.TYPE.SUCCESS,
                        toastID: toastIDs.shift(),
                        ...response.config.toastify.success,
                    })
                }

                return response;
            }, (error) => {
                // Handle errors
                if (error.config?.toastify?.error && toastIDs.length) {
                    updateToast({
                        type: toast.TYPE.ERROR,
                        toastID: toastIDs.shift(),
                        ...error.config.toastify.error,
                    })
                }

                return Promise.reject(error);
            });
        }

        // Cleanup function to eject interceptors when component unmounts
        return () => {
            if (axiosRequest) {
                axios.interceptors.request.eject(axiosRequest);
                axiosRequest = null;
            }
            if (axiosResponse) {
                axios.interceptors.response.eject(axiosResponse);
                axiosResponse = null;
            }
        }
    }, [account, instance]);

    return <>{children}</>;
};

export default RequestInterceptor;
import {toast} from "react-toastify";

function updateToast(props) {
    setTimeout(() => toast.update(props.toastID, {
        className: "flip animated",
        ...props
    }), 500);

    setTimeout(() => toast.update(props.toastID, {
        className: " ",
        autoClose: 5000,
    }), 1200);
}

export default updateToast;
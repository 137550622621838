import PropTypes from "prop-types";

const ConditionalWrapper = ({condition, wrapper, children}) => condition ? wrapper(children): children;

ConditionalWrapper.propsTypes = {
    condition: PropTypes.bool.isRequired,
    wrapper: PropTypes.element.isRequired,
    children: PropTypes.element.isRequired
}

export default ConditionalWrapper;
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as PropTypes from "prop-types";
import {faQuestionCircle} from "@fortawesome/pro-solid-svg-icons";

export default function IconTitle(props) {
    return React.createElement(props.type, {}, (
        <>
            <FontAwesomeIcon className={"fa-fw " + props.classIcon} icon={props.icon}/>
            <span className={props.classContent}>
                {props.content}
            </span>
        </>
    ));
}

export function IconTitles(props) {
    return props.titles.map((item, key) => {
        return <IconTitle key={key} {...item} />;
    })
}

IconTitles.propTypes = {
    titles: PropTypes.arrayOf(PropTypes.object).isRequired
};

IconTitle.defaultProps = {
    type: "h6",
    icon: faQuestionCircle,
    classIcon: "",
    content: "",
    classContent: "ml-4",
};

IconTitle.propTypes = {
    type: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
    classIcon: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    classContent: PropTypes.string,
};
import React, {useContext, useEffect} from "react";
import {Nav, Navbar as Bar, NavItem} from 'react-bootstrap'
import Aside from "./Aside";
import {faCircle, faPowerOff} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SearchBar from "./SearchBar";
import {AuthenticatedTemplate, useIsAuthenticated, useMsal} from "@azure/msal-react";
import {ThemeContext} from "../../store/context";
import ThemeModeMenu from "./ThemeModeMenu";

function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

function NavBar() {
    const {instance} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const {theme} = useContext(ThemeContext);
    const logo = require(`../../static/svg/logo${process.env.REACT_APP_DEPARTEMENT}.svg`).default;
    const logoDark = require(`../../static/svg/logo${process.env.REACT_APP_DEPARTEMENT}-dark.svg`).default;
    
    useEffect(() => {
        document.body.classList.toggle('no-sidebar', !isAuthenticated);
    })
    
    return (
        <Bar expand={"sm"}>
            {isAuthenticated && <Aside/>}
            <Bar.Brand as="a" href={process.env.REACT_APP_ROOT}>
                <h3>My.</h3>
                <img src={theme === "dark" || (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches)? logoDark : logo} alt="logo"/>
            </Bar.Brand>
            <AuthenticatedTemplate>
                <Bar.Toggle aria-controls="basic-navbar-nav"/>
                <Bar.Collapse id="basic-navbar-nav">
                    <Nav className="w-100">
                        <NavItem className={"nav-link w-100"} style={{cursor: "initial"}}>
                            <SearchBar/>
                        </NavItem>
                        <Nav.Item className={"nav-link"}>
                            <ThemeModeMenu/>
                        </Nav.Item>
                        <Nav.Item className={"nav-link"} onClick={() => handleLogout(instance)}>
                            <span className="fa-layers fa-3x">
                                <FontAwesomeIcon icon={faCircle} className="text-danger"/>
                                <FontAwesomeIcon icon={faPowerOff} className="text-white" transform="shrink-6"/>
                            </span>
                            <span>Déconnexion</span>
                        </Nav.Item>
                    </Nav>
                </Bar.Collapse>
            </AuthenticatedTemplate>
        </Bar>
    );
}

export default NavBar;
import React, {useContext, useEffect, useRef, useState} from 'react';
import MySelect from "../../components/form/MySelect";
import {Col, Collapse, FormLabel, Row} from "react-bootstrap";
import Form from "../../components/form/Form";
import axios from "axios";
import {ParametersContext} from "../../store/context/ParametersContext";
import FormGroup from "../../components/form/FormGroup";
import Security from "../../security";

export default function FichesUE() {
    const {parameters, updateParameters} = useContext(ParametersContext);
    const [value, setValue] = useState({});
    const [show, setShow] = useState(false);
    let formRef = useRef(null);
    
    useEffect(() => {
        updateParameters({Parameters: "PreviousCurrentAndNextUniteEnseignementID"});
        setShow(false);
    }, [updateParameters]);

    const fetch = async (d) => {
        formRef.reset();

        if (d === null) {
            setShow(false);
            return;
        }

        const {data} = await axios.get(`/screen/${Security.getScreenID("FichesUE")}?UniteEnseignementID=${d.value}`);
        if (data.result.length > 0) setValue(data.result[0]);
        else setValue({
            UniteEnseignementID: d.value
        });
        setShow(true);
    }

    const submit = async (d) => {
        await axios.post(`/screen/${Security.getScreenID("FichesUE")}`, d);
        fetch({value: d.UniteEnseignementID});
    }

    return <div>
        <section id={"FichesUE"}>
            <Row>
                <Col>
                    <FormLabel>Unité d'enseignement</FormLabel>
                    <MySelect
                        onChange={x => fetch(x)}
                        options={parameters.p?.PreviousCurrentAndNextUniteEnseignementID}
                        isClearable={true}
                    />
                </Col>
            </Row>
            <Collapse in={show}>
                <div>
                    <Form ref={x => formRef = x} onSubmit={x => submit(x)} defaultValues={value}>
                        <div>
                            <h3>
                                Objectifs et contributions de l'UE au programme
                            </h3>
                            <small>
                                - Préciser les objectifs : l’enseignant responsable de l’UE veillera à énoncer les
                                intentions, le projet et ce qui sera enseigné dans l’UE en mettant en évidence les objectifs
                                généraux de l’UE et, le cas échéant, la cohérence entre les AA.
                                <br/>
                                - Décrire la contribution de l’UE au programme : l’enseignant responsable identifiera un ou
                                plusieurs objectifs généraux (learning goals : LG) et acquis d’apprentissage visés (learning
                                outcomes : LO).
                                <br/>
                                NB : vous pouvez trouver sur Moodle -> Cellule d’Appui Pédagogique -> Objectifs généraux
                                (Learning goals) et les Acquis d’apprentissage visés (Learning objectives) pour les
                                programmes ICHEC.
                            </small>
                            <Row>
                                <Col>
                                    <FormGroup name={"DescriptionCoursObjectifsFR"} label={null} as="textarea" rows={6}/>
                                </Col>
                                <Col>
                                    <FormGroup name={"DescriptionCoursObjectifsEN"} label={null} as="textarea" rows={6}/>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <h3>
                                Prérequis et corequis
                            </h3>
                            <small>
                                - Identifier et décrire les savoirs et savoir-faire nécessaires à l’acquisition des
                                apprentissages dispensés dans l’UE.
                                <br/>
                                - Si l’UE comporte différentes AA, il faut rédiger cette rubrique pour l’UE en cohérence
                                avec les prérequis spécifiques aux différentes AA.
                                <br/>
                                - Suite au Décret paysage, certains prérequis ont été spécifiés dans le programme de
                                Bachelier à l’ICHEC (vous pouvez les trouver sur Moodle -> Cellule d’Appui Pédagogique ->
                                Prérequis dans les programmes ICHEC).
                                <br/>
                                NB : il n’y a aucun prérequis pour les cours de Master
                            </small>
                            <Row>
                                <Col>
                                    <FormGroup name={"DescriptionCoursPrerequisFR"} label={null} as="textarea" rows={6}/>
                                </Col>
                                <Col>
                                    <FormGroup name={"DescriptionCoursPrerequisEN"} label={null} as="textarea" rows={6}/>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <h3>
                                Description du contenu
                            </h3>
                            <small>
                                - S’il y a une cohérence entre les différentes AA, exposer le contenu de l’UE de façon
                                synthétique (les chapitres du cours, les différentes parties sans entrer dans tous les
                                détails de leur composition)
                                <br/>
                                - Dans le cas contraire, le contenu peut se décrire séparément pour chacune des AA composant
                                l’UE.
                            </small>
                            <Row>
                                <Col>
                                    <FormGroup name={"DescriptionCoursContenuFR"} label={null} as="textarea" rows={6}/>
                                </Col>
                                <Col>
                                    <FormGroup name={"DescriptionCoursContenuEN"} label={null} as="textarea" rows={6}/>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <h3>
                                Méthode(s) pédagogique(s)
                            </h3>
                            <small>
                                - Décrire de façon synthétique pour l’UE les activités d’enseignement et les ass
                                d’apprentissage dans lesquels le/les enseignant(s) veu(len)t impliquer l’étudiant.
                            </small>
                            <Row>
                                <Col>
                                    <FormGroup name={"DescriptionCoursPedagogieFR"} label={null} as="textarea" rows={6}/>
                                </Col>
                                <Col>
                                    <FormGroup name={"DescriptionCoursPedagogieEN"} label={null} as="textarea" rows={6}/>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <h3>
                                Type d'évaluation
                            </h3>
                            <small>
                                - Déterminer les formes et les méthodes d’évaluation pour l’UE qui permettront de faire état
                                du degré d’atteinte des objectifs d'apprentissage (définis préalablement) par les étudiants.
                                <br/>
                                - Décrire de façon synthétique pour l’UE les activités d’évaluation formatives et
                                certificatives qui seront organisées.
                                <br/>
                                - Si l’UE comporte plusieurs AA, il sera important de montrer, le cas échéant, la cohérence
                                entre les différents modes d’évaluation et favoriser, par exemple, l’évaluation intégrée.
                            </small>
                            <Row>
                                <Col>
                                    <FormGroup name={"DescriptionCoursTypeEvaluationFR"} label={null} as="textarea" rows={6}/>
                                </Col>
                                <Col>
                                    <FormGroup name={"DescriptionCoursTypeEvaluationEN"} label={null} as="textarea" rows={6}/>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <h3>
                                Supports et références bibliographiques
                            </h3>
                            <small>
                                - Préciser les supports de cours utilisés au sein de l’UE. Si l’UE comporte plusieurs AA,
                                les différents supports seront mentionnés.
                                <br/>
                                - Proposer une bibliographie reprenant les principales sources utilisées dans l’UE (ou dans
                                les AA).
                            </small>
                            <Row>
                                <Col>
                                    <FormGroup name={"DescriptionCoursReferencesFR"} label={null} as="textarea" rows={6}/>
                                </Col>
                                <Col>
                                    <FormGroup name={"DescriptionCoursReferencesEN"} label={null} as="textarea" rows={6}/>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </Collapse>
        </section>
    </div>;
}
import React from 'react';
import * as Yup from "yup";
import {TableInEdit} from "../utils/tables";
import {Form, FormGroup} from "../../components/form";

FormGroupement.schema = Yup.object({
    GroupementUniteEnseignementLibelle: Yup.string().required().label("Libelle"),
}).required()

export function FormGroupement({data, onSave}) {
    return (
        <Form onSubmit={d => onSave(d)} defaultValues={data} schema={FormGroupement.schema}>
            <FormGroup name={"GroupementUniteEnseignementLibelle"} readOnly={!data.IsNew} label={"Libelle"} rules={{required: true}}/>
            {
                !data.IsNew && <TableInEdit name={"UniteEnseignement"}
                                            columns={{"UE": x => x.UniteEnseignement.Display}}
                                            columnsExport={Object.values({"UE": x => x.UniteEnseignement.Display})}
                                            fieldName={"GroupementsUniteEnseignementUnitesEnseignements"}
                                            parameters={"UniteEnseignementID"}
                                            emptyValues={"GroupementUniteEnseignementUniteEnseignement"}
                                            values={data.GroupementsUniteEnseignementUnitesEnseignements}/>
            }
        </Form>
    );
}
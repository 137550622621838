import React, {PureComponent} from 'react';
import ScreenTable from "../../components/ScreenTable";
import Security from "../../security";
import {FormNiveauxCours} from "../../forms/gestionAcademique/FormNiveauxCours";

class NiveauxCours extends PureComponent {

    static columns = {
        "ID": "NiveauCoursID",
        "Code": "NiveauCoursCode",
        "Libelle": "NiveauCoursLibelle",
        "Diplôme lié": "Diplome",
        "Nombre d'UE": i => i.NiveauxCoursUnitesEnseignements.length,
        "Nombre d'UE dans l'année académique courante": i => i.NiveauxCoursUnitesEnseignements.filter(f => f.UniteEnseignement.Display.includes((new Date().getMonth() >= 9 ? new Date().getFullYear().toString().slice(-2) + "-" : "-" + new Date().getFullYear().toString().slice(-2)))).length,
    }; 

    componentWillUnmount() {
        this.setState = (state, callback) => {};
    }

    updateState(data) {
        this.setState({
            array: data.result,
        })
    }

    render() {
        return (
            <section>
                <ScreenTable
                    url={`/screen/${Security.getScreenID("NiveauxCours")}`}
                    updateState={this.updateState.bind(this)}
                    formEdit={<FormNiveauxCours/>}
                    formNew={<FormNiveauxCours/>}
                    Parameters={"DiplomeID,UniteEnseignementID"}
                    columns={NiveauxCours.columns}
                    columnsExport={["NiveauxCours"].concat(Object.values(NiveauxCours.columns))}
                    content={this.state?.array ?? []} />
            </section>
        );
    }
}

export default NiveauxCours;
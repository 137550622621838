import React from 'react';
import NavBar from "./NavBar";
import Social from "./Social";
import {AuthenticatedTemplate} from "@azure/msal-react";
import Sidebar from "./Sidebar";

function Header(props) {
    return (
        <header>
            <div>
                <NavBar {...props}/>
                <Social/>
            </div>
            <AuthenticatedTemplate>
                <Sidebar/>
            </AuthenticatedTemplate>
        </header>
    );
}

export default Header;
import React, {createContext, useReducer} from 'react';
import {initialParameters} from "../initialStates";
import axios from "axios";
import {toast} from "react-toastify";

const ParametersContext = createContext(initialParameters);

const ParametersProvider = ({children}) => {
    const [parameters, dispatchParameters] = useReducer((state, payload) => {
        if (payload === undefined) return state;

        let result = {...initialParameters};
        Object.keys(result).map(key => result[key] = {...state[key], ...payload[key]});
        return result;
    }, initialParameters);

    const updateParameters = async (params) => {
        if (typeof params === 'object') {
            let p = params.Parameters?.split(',').filter(s => !(s in parameters.p)).join(',') ?? "";
            let s = params.StaticValues?.split(',').filter(s => !(s in parameters.s)).join(',') ?? "";
            let e = params.EmptyValues?.split(',').filter(s => !(s in parameters.e)).join(',') ?? "";

            let toDispatch = {};
            if (p.length !== 0) toDispatch.p = p;
            if (s.length !== 0) toDispatch.s = s;
            if (e.length !== 0) toDispatch.e = e;

            if (Object.keys(toDispatch).length !== 0) {
                let result = await fetch(toDispatch);
                dispatchParameters(result);
                return result;
            }
        }
    }

    const fetch = async (params) => {
        try {
            let newParams = {};

            const {data} = await axios.get('/parameters', {
                params: params,
            });

            Object.entries(data.result).map(([key, value]) => {
                let newValue = value;

                if (Array.isArray(value)) {
                    newValue = {};
                    value.map(m => {
                        let options = [];
                        m.Values.map(n => options.push({value: Number(n.ID), label: n.Display}));
                        return newValue[m.Name] = options;
                    });
                }

                return newParams[key.toLowerCase().slice(0, 1)] = newValue;
            });

            return newParams;

        } catch (e) {
            console.log(e);
            toast.error("Le chargement des paramètres a échoué !");
        }
    };

    return <ParametersContext.Provider value={{parameters, updateParameters}}>
        {children}
    </ParametersContext.Provider>;
}

export { ParametersContext, ParametersProvider };
import React, {PureComponent} from 'react';
import ScreenTable from "../../components/ScreenTable";
import Security from "../../security";
import {FormFamille} from "../../forms/gestionAcademique";


class Familles extends PureComponent {

    static columns = {
        "ID": "FamilleUniteEnseignementID",
        "Libelle": "FamilleUniteEnseignementLibelle",
        "Type de famille": "TypeFamilleUniteEnseignement",
        "Nombre d'UE": i => i.FamillesUniteEnseignementUnitesEnseignements.length,
        "Niveau de cours lié": "NiveauCours",
    };

    componentWillUnmount() {
        this.setState = () => {};
    }

    updateState(data) {
        this.setState({
            array: data.result,
        })
    }

    render() {
        return (
            <section>
                <ScreenTable
                    url={`/screen/${Security.getScreenID("FamillesUnitesEnseignement")}`}
                    updateState={this.updateState.bind(this)}
                    formEdit={<FormFamille/>}
                    formNew={<FormFamille/>}
                    Parameters={"UniteEnseignementID,NiveauCoursID,TypeFamilleUniteEnseignementID"}
                    columns={Familles.columns}
                    columnsExport={["FamilleUniteEnseignementID"].concat(Object.values(Familles.columns))}
                    content={this.state?.array ?? []} />
            </section>
        );
    }
}

export default Familles;
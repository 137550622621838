import {Form, FormGroup} from "../../components/form";
import {Alert} from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../store/context";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";

QRCode.schema = Yup.object({
    key: Yup.string().min(5).max(5).required().label("Clé"),
}).required()

export default function QRCode() {
    let navigate = useNavigate();
    let params = useParams();
    let code = new URLSearchParams(window.location.search).get('code');
    const theme = useContext(ThemeContext);
    const [doc, setDoc] = useState({});
    const methods = useForm();
    
    const onSubmit = async (d) => {
        try {
            await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/qr?clé=${d.key}&code=${code}`, {responseType: "blob"})
                .then(res => {
                    console.log(res);
                    const url = window.URL.createObjectURL(res.data);
                    const link = document.createElement('a');
                    link.href = url;
                    //link.setAttribute('download', res.headers["content-disposition"].match(/filename\s*=\s*(.+)/i)[1].split(";")[0]); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                })
        } catch (xhr) {
            console.log(xhr);
            if (xhr.response !== undefined) {
                if(xhr.response.status === 404){
                    const blob = xhr.response.data;
                    const text = await blob.text();
                    const data = JSON.parse(text);

                    toast.error(data.message);
                } else
                if (xhr.response.status === 409) {
                    methods.setError("key", {type: "custom", message: "error"})
                } else {
                    navigate('/*');
                    return null;
                }
            }
        }
    }

    useEffect(() => {
        const fetch = async (code) => {
            try {
                const {data} = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/QR/${code}`);
                setDoc(data);
            } catch (e) {
                console.log(e);
                if (e.response !== undefined && e.response.status === 404) {
                    navigate('/*');
                    return null;
                }
            }
        }
        fetch(code);
    }, [code, navigate]);

    if (code === null || code === undefined) {
        if (params.Code !== undefined) {
            code = params.Code;
        } else {
            navigate('/*');
            return null;
        }
    }
    
    return (
        <div className={"px-lg-5 mx-lg-5"}>
            <Alert
                variant={(theme.theme === "dark" || (theme.theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) ? "light" : "dark")}
                className={"m-lg-5"}>
                <h1 className={'text-center'}>Vérification d'authenticité</h1>
                <p>Ce document, nommé <b>{doc.DocumentCertificationNom}</b>, a été généré le
                    : <b>{doc.DocumentCertificationDate}</b> à <b>{doc.DocumentCertificationHeure}</b></p>
                <p>Pour une validation complète, vous pouvez télécharger l’intégralité du document.</p>
                <p>Veuillez mentionner la clé en 5 caractères, qui apparait sous le QR Code.</p>
                    <Form {...methods} onSubmit={d => onSubmit(d)} schema={QRCode.schema}>
                        <FormGroup name={"key"} label={"Clé"} rules={{required: true, minLength: 5, maxLength: 5}} minLength={5} maxLength={5} />
                    </Form>
            </Alert>
        </div>
    );
}
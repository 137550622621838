import React from 'react';
import {Form} from "react-bootstrap";
import PropTypes from 'prop-types';
import {isString} from 'lodash';
import {t} from "i18next";
import {useFormContext} from "react-hook-form";

const FormGroup = React.forwardRef(({name, label, type, rules = {}, ...rest}, ref) => {

    const {register, control, formState: {errors}} = useFormContext();
    return <Form.Group controlId={name} className={"form-group"}>
        {label != null && <Form.Label className={rules.required && "required"}>{label}</Form.Label>}
        {isString(type)
            ? <>
                <Form.Control name={name}
                              ref={ref}
                              isInvalid={!!errors[name]}
                              {...register(name, rules)}
                              {...rest}/>
                <Form.Control.Feedback type="invalid">
                    {t(errors[name]?.type)}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">
                    {t(errors[name]?.type)}
                </Form.Control.Feedback>
            </>
            : React.createElement(type, {
                ...{
                    ...rest,
                    ...register(name, rules),
                    control: control,
                    name: name,
                }
            })}
    </Form.Group>
});


FormGroup.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
};

FormGroup.defaultProps = {
    type: "input",
};

export default FormGroup;
import React, {useState} from 'react';
import Select from "react-select";

const customTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: "rgba(91,232,207,.5)",
        primary25: "var(--tablesorter-even-hover-bg)",
    }
})

const MySelect = React.forwardRef((props, ref) => {
    const [disabled] = useState(props.disabled);
    //let isValid = props.required !== undefined ? props.required && !!!props.errors : true;

    const customStyle = {
        menuList: (previous, state) => ({
            ...previous,
            boxShadow: "0 0 0 1px hsla(360, 100%, 100%, 0.1),0 4px 11px hsla(360, 100%, 100%, 0.1)"
        }),

        control: (previous, state) => ({
            ...previous,
            boxShadow: state.isFocused ? `0 0 0 0.1rem rgba(0, 69, 138, 0.25)` : null,
            borderColor: "hsl(0deg, 0%, 80%)",
            backgroundColor: state.isDisabled ? "#e9ecef" : null,
            //borderColor: state.isFocused ? "hsl(0,0%,80%)" : (isValid ? "hsl(0,0%,80%)" : "#dc3545")
        }),

        singleValue: (previous, state) => ({
            ...previous,
            color: state.isDisabled ? "#212529" : null,
        }),

        indicatorsContainer: (previous, state) => ({
            ...previous,
            color: state.isDisabled ? "#212529 !important" : null
        }),

        menu: (previous, state) => ({
            ...previous,
            zIndex: "11",
            backgroundColor: "var(--body-bg)",
        }),

        option: (previous, state) => ({
            ...previous,
            color: state.isSelected ? "neutral100" : null,
            boxShadow: state.isFocused ? "rgba(0, 0, 0, 0.075) 0px 0px 0px 9999px inset" : null
        }),

        input: (p,s) => ({
            ...p,
            color: "var(--body-color)",
        }),
    }
    
    return (
        <Select
            ref={ref}
            isDisabled={disabled}
            theme={customTheme}
            styles={customStyle}
            {...props}
        />
    );
});

MySelect.defaultProps = {
    disabled: false,
};

export default MySelect;
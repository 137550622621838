import { Button } from "react-bootstrap";
import React from "react";
import {useMsal} from "@azure/msal-react";
import {graphScopes} from "../../utils";

async function handleLogin(instance) {
    await instance.loginPopup(graphScopes).catch(async e => {
        if (e.errorCode === "hash_empty_error") {
            await handleLogin(instance);
        } else {
            console.error(e);
        }
    });
}

export default function Welcome() {
    const { instance } = useMsal();

    document.body.classList.add('pace-done');

    return (
        <div className={"bg-light rounded-3 text-center"} style={{ marginTop: "-1.5rem", padding: "5rem 0 4rem" }}>
            <section>
                <h1>Bienvenue!</h1>
                <p>
                    Les accès digitaux de l'ICHEC réunis <br/>dans un seul et même endroit.
                </p>
                <p style={{paddingTop: "0.8rem"}}>
                    <Button variant="primary" size={"lg"} onClick={() => handleLogin(instance)}>Se connecter</Button>
                </p>
            </section>
        </div>
    );
}
import React, {createContext, useEffect, useReducer} from 'react';
import {Offcanvas} from "react-bootstrap";
import {initialEdit} from "../initialStates";
import {useLocation} from "react-router-dom";
import $ from "jquery";

export const EditContext = createContext();

export const EditProvider = ({children}) => {
    const [store, dispatch] = useReducer((state, payload) => {
        if (payload.title !== undefined || payload.content !== undefined) return {
            show: payload.showOrOnClose,
            title: payload.title,
            content: payload.content,
            onClose: state.onClose
        }

        if (typeof payload.showOrOnClose === "function") return {...state, onClose: payload.showOrOnClose}

        return {...state, show: payload.showOrOnClose}
    }, initialEdit);

    let location = useLocation();
    useEffect(() => {
        update(false);
    }, [location]);

    useEffect(() => {
        document.body.classList.toggle('editPanel', store.show);
    })

    const update = (showOrOnClose, title, content) => {
        dispatch({showOrOnClose, title, content});
    }

    const close = () => {
        let current = $('table').find('.table-secondary');
        current.removeClass();

        if (current.length !== 0) {
            setTimeout(() => $('html,body').animate({scrollTop: $('[data-id=' + current.data('id') + ']').offset().top - 180 - $('thead').height()}, 0), 100);
        }

        update(false);
        if (store.onClose !== null) store.onClose();
    }

    return (
        <EditContext.Provider value={{editStore: store, update, close}}>
            {children}
            <Offcanvas show={store.show} onHide={close} placement={"end"} scroll={true} backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        {store.title}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {store.content}
                </Offcanvas.Body>
            </Offcanvas>
        </EditContext.Provider>
    )
}
import {flattenDeep} from "lodash";
import {matchPath} from "react-router-dom";

/**
 * Combine paths
 *
 * @param {string} parent
 * @param {string} child
 * @returns {string}
 */
export const combinePaths = (parent, child) =>
    `${parent.replace(/\/$/, "")}/${child.replace(/^\//, "")}`;

/**
 * Recursively build paths for each navigation item
 *
 * @param routes
 * @param {string} parentPath
 * @returns {*}
 */
export const buildPaths = (routes, parentPath = "") =>
    routes.map(route => {
        const path = combinePaths(parentPath, route.path);

        return {
            ...route,
            path,
            realPath: path,
            params: {},
            ...(route.children && {children: buildPaths(route.children, path)})
        };
    });

/**
 * Recursively provide parent reference for each navigation item
 *
 * @param routes
 * @param parentRoute
 * @returns {*}
 */
export const setupParents = (routes, parentRoute = null) =>
    routes.map(route => {
        const withParent = {
            ...route,
            ...(parentRoute && {parent: parentRoute})
        };

        return {
            ...withParent,
            ...(withParent.children && {
                children: setupParents(withParent.children, withParent)
            })
        };
    });

/**
 * Convert navigation tree into flat array
 *
 * @param routes
 * @returns {any[]}
 */
export const flattenRoutes = routes => flattenDeep(routes.map(route => [route.children ? flattenRoutes(route.children) : [], route]));

/**
 * Combine all the above functions together
 *
 * @param routes
 * @returns {any[]}
 */
export const generateAppRoutes = routes => {
   return flattenRoutes(setupParents(buildPaths(routes)));
}

/**
 * Provides path from root to the element
 *
 * @param route
 * @returns {any[]}
 */
export const pathTo = route => {
    if (route === undefined) return;

    if (!route.parent) return [route];

    return [...pathTo(route.parent), route];
};

export const getCurrentRoute = (routes, location) => {
    if (routes === undefined) return undefined;

    routes = generateAppRoutes(routes);

    let current = routes.slice(0, -1).filter(f => matchPath(f.path, location.pathname));

    if (current.length > 1 || current.length === 0) {
        current = undefined;
    } else {
        current = current[0];
        let toParam = matchPath(current.path, location.pathname);
        current = pathTo(current);
        Object.entries(toParam.params).forEach(([k, v]) => current.forEach(f => {
            if (f.realPath.includes(":" + k)) {
                f.path = f.realPath.replace(":" + k, v);
                f.params[k] = v;
            }
        }));
    }

    return current;
}
import React, {useContext} from 'react';
import * as Yup from "yup";
import moment from "moment";
import {ParametersContext} from "../../store/context";
import {Form, FormCheckbox, FormGroup, FormSelect} from "../../components/form";

FormDiplomes.schema = Yup.object({
    DiplomeLibelle: Yup.string().max(100).required().label("Libelle"),
    DiplomeLibelleOfficiel: Yup.string().max(100).required().label("Libelle officiel"),
    DiplomeLibelleOfficielEN: Yup.string().nullable().max(100).label("Libelle officiel en anglais"),
    ModaliteID: Yup.number().nullable().positive().integer().label("Modalité"),
    TypeDiplomeID: Yup.number().nullable().positive().integer().label("Type de diplôme"),
    TypeDiplomeOfficielID: Yup.number().nullable().positive().integer().label("Type de diplôme"),
    DiplomeURL: Yup.string().nullable().url().label("URL"),
    DiplomeCodeCouleur: Yup.string().nullable().max(10).matches(/^#.*/).label("Code couleur"),
    MembrePersonnelID: Yup.number().required().positive().integer().label("Type de diplôme"), //Responsable
    DiplomeVolumeHoraire: Yup.string().nullable().max(100).label("Volume horaire"),
    DiplomeDureeTotale: Yup.string().nullable().max(100).label("Durée totale"),
    DiplomeDureeTotaleAnnee: Yup.number().nullable().positive().integer().min(1).label("Durée totale annuelle"),
    DiplomeHeureDebut: Yup.string().nullable().label('Heure de début des cours'),
    DiplomeHeureFin: Yup.string().nullable().test("is-greater", "end time should be greater", (value, context) => {
        return moment(value.replace("h", ":"), "HH:mm").isSameOrAfter(moment(context.parent.DiplomeHeureDebut.replace("h", ":"), "HH:mm"));
    }),
    DiplomeSection: Yup.string().nullable().max(100).label('Section'),
    DiplomeCodeSection: Yup.string().nullable().max(100).label('Code section'),
    DiplomeGroupeSection: Yup.string().nullable().max(50).label('Section des bacs'),
    DiplomeECTS: Yup.number().positive().integer().label("ECTS"),
    DiplomeLibelleOfficielComplement: Yup.string().nullable().max(100).label("Complément du libellé officiel"),
    DiplomeLibelleOfficielComplementEN: Yup.string().nullable().max(100).label("Complément du libellé en anglais"),
    DiplomeCommentaires: Yup.string().nullable().label("Commentaires"),
    DiplomeCommentairesEN: Yup.string().nullable().label("Commentaires en anglais"),
    DiplomeDescription: Yup.string().nullable().label("Description"),
    DiplomeDescriptionEN: Yup.string().nullable().label("Description en anglais"),
}).required()

export function FormDiplomes({data, onSave}) {
    const {parameters} = useContext(ParametersContext);
    
    return (
        <Form onSubmit={d => onSave(d)} defaultValues={data} schema={FormDiplomes.schema}>
            <FormGroup name={"DiplomeLibelle"} label={"Libellé"} rules={{required: true}}/>
            <FormGroup name={"DiplomeLibelleOfficiel"} label={"Libellé officiel"} rules={{required: true}}/>
            <FormGroup name={"DiplomeLibelleOfficielComplement"} label={"Complément du libellé officiel"} />

            <FormGroup name={"MembrePersonnelID"} label={"Responsable"} type={FormSelect} options={parameters.p.MembrePersonnelID} rules={{required: true}}/>
            <FormGroup name={"ModaliteID"} label={"Modalité"} type={FormSelect} options={parameters.p.ModaliteID}/>
            <FormGroup name={"TypeDiplomeID"} label={"Type de diplôme"} type={FormSelect} options={parameters.p.TypeDiplomeID}/>
            <FormGroup name={"TypeDiplomeOfficielID"} label={"Type de diplôme officiel"} type={FormSelect} options={parameters.p.TypeDiplomeOfficielID}/>
            <FormGroup name={"DiplomeECTS"} label={"ECTS"} />
            <FormGroup name={"DiplomeSection"} label={"Section"} />
            <FormGroup name={"DiplomeCodeSection"} label={"Code section"} />
            <FormGroup name={"DiplomeGroupeSection"} label={"Section des BACs"} />
            <FormGroup name={"DiplomeURL"} label={"URL"} />
            <FormGroup name={"DiplomeCodeCouleur"} label={"Code couleur"} />
            <FormCheckbox name={"DiplomeActif"} label={"Actif"} defaultValue={1} edit={true}/>
            <FormCheckbox name={"DiplomeVisibleIchecBE"} label={"Visibilité ICHEC.be"} edit={true}/>
            <FormGroup name={"DiplomeVolumeHoraire"} label={"Volume horaire"} />
            <FormGroup name={"DiplomeDureeTotale"} label={"Durée totale"} />
            <FormGroup name={"DiplomeDureeTotaleAnnee"} label={"Durée totale annuelle"} />
            <FormGroup name={"DiplomeHeureDebut"} label={"Heure de début des cours"} />
            <FormGroup name={"DiplomeHeureFin"} label={"Heure de fin des cours"} />
            <FormGroup name={"DiplomeDescription"} label={"Description"} as="textarea" rows={3}/>
            <FormGroup name={"DiplomeCommentaires"} label={"Commentaires"} as="textarea" rows={3}/>
            <hr/>
            <FormGroup name={"DiplomeLibelleOfficielEN"} label={"Libelle officiel en anglais"} />
            <FormGroup name={"DiplomeLibelleOfficielComplementEN"} label={"Complément du libellé officiel en anglais"} />
            <FormGroup name={"DiplomeDescriptionEN"} label={"Description en anglais"} as="textarea" rows={3}/>
            <FormGroup name={"DiplomeCommentairesEN"} label={"Commentaires en anglais"} as="textarea" rows={3}/>
        </Form>
    );
}
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import {MsalProvider} from "@azure/msal-react";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./utils/authConfig";
import {ParametersProvider} from "./store/context";
import RequestInterceptor from "./utils/requestInterceptor";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    // <React.StrictMode>
    <MsalProvider instance={msalInstance}>
        <RequestInterceptor>
            <ParametersProvider>
                <App/>
            </ParametersProvider>
        </RequestInterceptor>
    </MsalProvider>
    // </React.StrictMode>
    ,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*
reportWebVitals();
const reportWebVitals = onPerfEntry => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
            getCLS(onPerfEntry);
            getFID(onPerfEntry);
            getFCP(onPerfEntry);
            getLCP(onPerfEntry);
            getTTFB(onPerfEntry);
        });
    }
};
*/
import React, {useContext, useEffect} from 'react';
import {BrowserRouter, useNavigate, useRoutes} from 'react-router-dom';
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import Security from "../security";
import {AppContext, EditProvider, GlobalProvider} from "../store/context";
import {Breadcrumb, Header} from "../components/visual";

function Router() {
    return (
        <BrowserRouter>
            <GlobalProvider>
                <Header/>
                <main>
                    <AuthenticatedTemplate>
                        <MyAuthenticatedRouter/>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <MyUnauthenticatedRouter/>
                    </UnauthenticatedTemplate>    
                </main>
            </GlobalProvider>
        </BrowserRouter>
    );
}

function MyAuthenticatedRouter() {
    const {store} = useContext(AppContext);
    const Routes = () => useRoutes(store.authenticatedRoutes);
    let navigate = useNavigate();

    useEffect(() => {
        if (Security.screenAccessInitialized === false || !Security.isAuthenticated()) {
            Security.firstPath = window.location.pathname;
        }
        navigate(process.env.REACT_APP_ROOT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Breadcrumb/>
            <EditProvider>
                <Routes/>
            </EditProvider>
        </>
    )
}

function MyUnauthenticatedRouter() {
    const {store} = useContext(AppContext);
    const Routes = () => useRoutes(store.unauthenticatedRoutes);
    
    return (
        <>
            <Breadcrumb/>
            <Routes/>
        </>
    )
}

export default Router;
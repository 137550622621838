import React, {useEffect} from 'react';
import {useForm, FormProvider} from "react-hook-form";
import {Button, Form as BSForm} from "react-bootstrap";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const Form = React.forwardRef(({onSubmit, onDelete = null, children, defaultValues, schema = null, ...rest}, ref) => {
    if (schema === null) schema = Yup.object({});
    const methods = { ...useForm({defaultValues, resolver: yupResolver(schema)}), ...rest};
    const {handleSubmit, reset} = methods;
    let navigate = useNavigate();

    useEffect(() => reset(defaultValues), [defaultValues, reset]);

    const deleteAction = async () => {
        console.log(defaultValues);

        if (window.confirm("delete?") && onDelete != null) {
            await axios.delete(`/delete/${defaultValues[defaultValues["IDPropertyName"]]}/${defaultValues["IDPropertyName"]}`);
            let location = window.location.pathname;
            navigate('/');
            setTimeout(() => navigate(location), 200);
        }
    }

    return (
        <FormProvider {...methods}>
            <BSForm className={onDelete != null && "canDelete"} ref={ref}>
                {children}
                <div className={"buttons d-grid gap-2"}>
                    {onDelete != null &&
                        <Button variant={"danger"}
                                onClick={deleteAction}>
                            Supprimer
                        </Button>}
                    <Button variant={"success"}
                            type={"submit"}
                            onClick={handleSubmit(x => onSubmit(x))}>
                        Sauver
                    </Button>
                </div>
            </BSForm>
        </FormProvider>
    );
})

export default Form;
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileAlt, faFileCsv, faUserShield} from "@fortawesome/pro-solid-svg-icons";
import moment from 'moment';
import {Button} from "react-bootstrap";

const ExportTable = ({content, columnsExport = [], exportName = ''}) => {

    const to = (type) => {

        let result = [];

        console.log(content);

        if (content.length > 0) {
            result = [
                Object.keys(content[0]).filter(f => columnsExport.includes(f)),
                ...content.map(m => Object.values(Object.fromEntries(Object.entries(m).filter(([key, f]) => columnsExport.includes(key)))))
            ];

            console.log(columnsExport);

            result.forEach((f, i) => {
                columnsExport.forEach((g, j) => {
                    if (i === 0) {
                        if (typeof g === "function") {
                            f.push(g.name);
                        }
                    } else {
                        if (typeof g === "function") {
                            f.push(g(content[i-1]));
                        }
                    }

                })
            })

            console.log(result);
        }

        let csv = result.map(e => e.map(x => {
            if(typeof x === "string") x = x.replace("\r", "")
            return x
        }).join(";")).join("\n");
        let downloadLink = document.createElement("a");
        let blob = new Blob(["\ufeff", csv]);
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `data_${moment().format('YYYYMMDDhhmmss')}${exportName !== '' ? `_${exportName}` : ""}.${type}`;
        downloadLink.target = '_blank';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <>
            {content.length} résultat{content.length > 1 && "s"}
            <div>
                <Button variant={"link"} onClick={() => to('csv')} title="CSV(;)">
                    <FontAwesomeIcon icon={faFileCsv}/> CSV (;)
                </Button>
                &nbsp;&nbsp;
                <Button variant={"link"} onClick={() => to('txt')} title="TXT">
                    <FontAwesomeIcon icon={faFileAlt}/> TXT
                </Button>
                <span className={"d-none"}>
                    &nbsp;
                    |&nbsp;&nbsp;
                    <span className="text-danger text-center"
                          title="Ce rapport n'est pas encore approuvé par la RGPD">
                    <FontAwesomeIcon icon={faUserShield}/>
                </span>
                </span>
            </div>
        </>
    );
};

export default ExportTable;
import React, {useContext} from 'react';
import * as Yup from "yup";
import {ParametersContext} from "../../store/context";
import {TableInEdit} from "../utils/tables";
import {Form, FormCheckbox, FormGroup, FormSelect} from "../../components/form";

FormNiveauxCours.schema = Yup.object({
    NiveauCoursCode: Yup.string().max(100).required().label("Code"),
    NiveauCoursLibelle: Yup.string().max(100).required().label("Libelle"),
    NiveauCoursCodeMinistere: Yup.string().max(100).label("Code ministère"),
    NiveauCoursNombreOptions: Yup.number().min(0).integer().label("Nombre d'options à choisir"),
    NiveauCoursNumeroBloc: Yup.number().positive().integer().nullable().label("Numéro du bloc"),
    NiveauCoursCodeCouleur: Yup.string().nullable().label("Code couleur"),
    NiveauCoursGestionnairesAdministratifs: Yup.string().nullable().label("Gestionnaires administratifs"),
    NiveauCoursCoordinateurs: Yup.string().nullable().label("Coordinateurs"),
    DiplomeID: Yup.number().integer().required().label("Diplome lié"),
}).required()

export function FormNiveauxCours({data, onSave}) {
    const {parameters} = useContext(ParametersContext);
    
    return (
        <Form onSubmit={d => onSave(d)} defaultValues={data} schema={FormNiveauxCours.schema}>
            <FormGroup name={"DiplomeID"} label={"Diplome lié"} rules={{required: true}} type={FormSelect} options={parameters.p.DiplomeID}/>

            <FormGroup name={"NiveauCoursCode"} label={"Code"} rules={{required: true}}/>
            <FormGroup name={"NiveauCoursLibelle"} label={"Libellé"} rules={{required: true}}/>
            <FormGroup name={"NiveauCoursCodeMinistere"} label={"Code ministère"} />
            <FormGroup name={"NiveauCoursNombreOptions"} label={"Nombre d'options à choisir"} />
            <FormCheckbox name={"NiveauCoursHistorique"} label={"Archivé"} defaultValue={0} edit={true}/>
            <FormCheckbox name={"NiveauCoursVisibleIchecBE"} label={"Visibilité ICHEC.be"} edit={true}/>
            <FormGroup name={"NiveauCoursNumeroBloc"} label={"Numéro du bloc"} />
            <FormGroup name={"NiveauCoursCodeCouleur"} label={"Code couleur"} />
            <FormGroup name={"NiveauCoursGestionnairesAdministratifs"} label={"Gestionnaires administratifs"} as="textarea" rows={3}/>
            <FormGroup name={"NiveauCoursCoordinateurs"} label={"Coordinateurs"} as="textarea" rows={3}/>
            {
                !data.IsNew && <TableInEdit name={"UniteEnseignement"}
                                            columns={{"UE": x => x.UniteEnseignement.Display}}
                                            columnsExport={Object.values({"UE": x => x.UniteEnseignement.Display})}
                                            fieldName={"NiveauxCoursUnitesEnseignements"}
                                            parameters={"UniteEnseignementID"}
                                            emptyValues={"NiveauCoursUniteEnseignement"}
                                            values={data.NiveauxCoursUnitesEnseignements}/>
            }
        </Form>
    );
}
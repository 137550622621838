import React from 'react';
import {Link} from 'react-router-dom';

export default function NotFound() {
	return (
		<section>
			<h1>404</h1>
			<h6>Not Found</h6>
			<br/>
			<br/>
			<Link to={process.env.REACT_APP_ROOT}>Go back</Link>
		</section>
	);
}

import React, {useContext} from 'react';
import * as Yup from "yup";
import {TableInEdit} from "../utils/tables";
import {ParametersContext} from "../../store/context";
import {Form, FormGroup, FormSelect} from "../../components/form";

FormTravauxPratiques.schema = Yup.object({
    GroupeActiviteApprentissageCode: Yup.string().required().label("Libelle"),
    GroupeActiviteApprentissageNumero: Yup.number().required().integer().label("Numéro"),
    ActiviteApprentissageID: Yup.number().required().positive().integer().label("Activité d'apprentissage"),
    GroupeActiviteApprentissageNbPlaces: Yup.number().min(0).required().integer().label("Capacité"),
}).required()

export function FormTravauxPratiques({data, onSave, AnneeAcademique}) {
    const {parameters} = useContext(ParametersContext);
    
    return (
        <Form onSubmit={d => onSave(d)} defaultValues={data} schema={FormTravauxPratiques.schema}>
            <FormGroup name={"ActiviteApprentissageID"} label={"Activité d'apprentissage"} readOnly={!data.IsNew} type={FormSelect}
                       options={parameters.p[`ActiviteApprentissageAnneeAcademiqueID-${AnneeAcademique}`]} rules={{required: true}}/>
            <FormGroup name={"MembrePersonnelID"} label={"Responsable"} type={FormSelect} options={parameters.p.MembrePersonnelID} rules={{required: true}}/>
            <FormGroup name={"GroupeActiviteApprentissageCode"} label={"Libelle"} readOnly={!data.IsNew} rules={{required: true}}/>
            <FormGroup name={"GroupeActiviteApprentissageNumero"} label={"Numéro"} readOnly={!data.IsNew} rules={{required: true}}/>
            <FormGroup name={"GroupeActiviteApprentissageNbPlaces"} label={"Capacité"} rules={{required: true}}/>
            <FormGroup name={"GroupeActiviteApprentissagePlageHoraire"} label={"Plage horaire"}/>
            {
                !data.IsNew && <TableInEdit name={"ProgrammesEtudiants"}
                                            columns={{"Etudiants": x => x.Display}}
                                            columnsExport={Object.values({"Etudiants": x => x.Display})}
                                            fieldName={"ProgrammesEtudiants"}
                                            reloadParameters={true}
                                            parameters={`TravauxPratiquesID-${data.ActiviteApprentissageID}`}
                                            values={data.ProgrammesEtudiants}/>
            }
        </Form>
    );
}

// eslint-disable-next-line no-unused-vars
import React from 'react';

let firstPath = process.env.REACT_APP_ROOT;
let screenAccessInitialized = false;
let screens = [];
let currentUser = null;

const isAuthenticated = () => currentUser !== null;

const getScreenID = (screenName) => screens.length > 0 ? screens.find(f => f.label === screenName).value : screens;

const getScreenName = (screenID) => screens.length > 0 ? screens.find(f => f.value === screenID).label : screens;

const setScreens = (s) => {
    screens = s;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    firstPath,
    screenAccessInitialized,
    isAuthenticated,
    getScreenID,
    getScreenName,
    setScreens,
}
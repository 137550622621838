import React, {PureComponent} from 'react';
import ScreenTable from "../../components/ScreenTable";
import Security from "../../security";
import MyCheckbox from "../../components/form/MyCheckbox";
import {FormDiplomes} from "../../forms/gestionAcademique/FormDiplomes";

class Diplomes extends PureComponent {

    static columns = {
        "ID": "DiplomeID",
        "Libelle": "DiplomeLibelle",
        "Modalité": "Modalite",
        "Responsable": "MembrePersonnel",
        "Nb de niveaux de cours": "NbNiveaux",
        "Actif":  i => <MyCheckbox checked={i.DiplomeActif} style={{display: "flex", justifyContent: "center"}}/>
    };

    componentWillUnmount() {
        this.setState = (state, callback) => {};
    }

    updateState(data) {
        this.setState({
            array: data.result,
        })
    }

    render() {
        return (
            <section>
                <ScreenTable
                    url={`/screen/${Security.getScreenID("Diplomes")}`}
                    updateState={this.updateState.bind(this)}
                    formEdit={<FormDiplomes/>}
                    formNew={<FormDiplomes/>}
                    Parameters={"ModaliteID,TypeDiplomeID,TypeDiplomeOfficielID,MembrePersonnelID"}
                    columns={Diplomes.columns}
                    columnsExport={["Diplomes"].concat(Object.values(Diplomes.columns))}
                    content={this.state?.array ?? []} />
            </section>
        );
    }
}

export default Diplomes;
import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/pro-solid-svg-icons";
import Security from "../security";
import {ParametersContext} from "../store/context";
import {useNavigate} from "react-router-dom";

export default function InitApp() {
    let navigate = useNavigate();
    const {updateParameters} = useContext(ParametersContext);
    const [isDarkMode, setIsDarkMode] = useState(
        window.matchMedia('(prefers-color-scheme: dark)').matches
    );

    // Update the state if the user's preference changes
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e) => setIsDarkMode(e.matches);

        mediaQuery.addEventListener('change', handleChange);

        // Cleanup on unmount
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    useEffect(() => {
        const fetch = async () => {
            const response = await updateParameters({
                StaticValues: "WorkAnneeAcademiqueID",
                Parameters: "ScreenLocationID"
            });

            if (response) {
                Security.setScreens(response.p.ScreenLocationID);
                Security.screenAccessInitialized = true;
                setTimeout(() => navigate(Security.firstPath), 1000);
            }
        }

        fetch();
    }, [navigate, updateParameters]);

    return (
        <section style={{ textAlign: "center" }}>
            <h1 className={isDarkMode ? "darkTheme" : ""} style={{display: "flex", alignItems: "center", gap: "16px"}}>
                <FontAwesomeIcon icon={faPen} style={{fontSize: "0.5em"}}/>
                <span>my.ICHEC</span>
            </h1>
        </section>
    );
}
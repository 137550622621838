import React, {useContext} from 'react';
import * as Yup from "yup";
import {TableInEdit} from "../utils/tables";
import {ParametersContext} from "../../store/context";
import {Form, FormGroup, FormSelect} from "../../components/form";

FormFamille.schema = Yup.object({
    FamilleUniteEnseignementLibelle: Yup.string().required().label("Libelle"),
    NiveauCoursID: Yup.number().positive().integer().required().label("Niveau de cours lié"),
}).required()

export function FormFamille({data, onSave}) {
    const {parameters} = useContext(ParametersContext);
    
    return (
        <Form onSubmit={d => onSave(d)} defaultValues={data} schema={FormFamille.schema}>
            <FormGroup name={"FamilleUniteEnseignementLibelle"} readOnly={!data.IsNew} label={"Libelle"} rules={{required: true}}/>
            <FormGroup name={"TypeFamilleUniteEnseignementID"} label={"Type de famille"} type={FormSelect} options={parameters.p.TypeFamilleUniteEnseignementID} rules={{required: true}}/>
            <FormGroup name={"NiveauCoursID"} label={"Niveau de cours lié"} type={FormSelect} options={parameters.p.NiveauCoursID} rules={{required: true}}/>

            {
                !data.IsNew && <TableInEdit name={"UniteEnseignement"}
                                            columns={{"UE": x => x.UniteEnseignement.Display}}
                                            columnsExport={Object.values({"UE": x => x.UniteEnseignement.Display})}
                                            fieldName={"FamillesUniteEnseignementUnitesEnseignements"}
                                            parameters={"UniteEnseignementID"}
                                            emptyValues={"FamilleUniteEnseignementUniteEnseignement"}
                                            values={data.FamillesUniteEnseignementUnitesEnseignements}/>
            }

            <hr/>
            <FormGroup name={"FamilleUniteEnseignementLibelleEN"} label={"Libelle en anglais"}/>
            <FormGroup name={"FamilleUniteEnseignementDescription"} label={"Description"} as="textarea" rows={3}/>
        </Form>
    );
}
import React, {useContext} from 'react';
import {useNavigate, useOutlet} from "react-router-dom";
import $ from "jquery";
import TableSorter from "../../../components/TableSorter";
import {GlobalContext} from "../../../store/context/GlobalContext";

export default function Etudiants() {
    const {store} = useContext(GlobalContext);
    const outlet = useOutlet();
    let navigate = useNavigate();

    if (outlet === null) {
        $('table').find('.table-secondary').removeClass('table-secondary');
    }

    const onClick = (elm) => {
        navigate(`${window.location.pathname}/${elm.currentTarget.dataset.id}`);
    }

    return (
        <>
            {outlet}
            <section className={outlet === null ? "" : "d-none"}>
                <TableSorter
                    onClick={onClick}
                    columns={{
                        "Matricule": "EtudiantMatricule",
                        "NOM Prénom": "Etudiant",
                        "Année académique": "AnneeAcademiqueValeur",
                        "Diplôme": "DiplomeLibelle",
                        "Statut inscription": "StatutInscription"
                    }}
                    content={store.etudiants}/>
            </section>
        </>
    );
}

import React, {Component} from 'react';
import * as PropTypes from "prop-types";

import $ from 'jquery';
import 'tablesorter/dist/js/jquery.tablesorter.min';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ConditionalWrapper, ExportTable} from "./utils";

class TableSorter extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.content !== this.props.content
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.$el.trigger("update");
        this.$el.trigger("sorton", [[[0, 0]]]);
    }

    componentDidMount() {
        this.$el = $(this.el);
        this.$el.tablesorter({
            sortMultiSortKey: 'altKey',
        });
        this.hasStudent = $('main').has("#Student").length > 0;
        if (this.props.onClick !== null) {
            this.$el.on('click', 'tbody tr', this.onClick.bind(this));
        }
    }

    componentWillUnmount() {
        this.$el.tablesorter('destroy');
    }

    onClick(elm) {
        let current = $('table').find('.table-secondary');
        let target = $(elm.currentTarget);

        if (current.length === 0) {
            target.addClass('table-secondary');

            setTimeout(() => $('html,body').animate({scrollTop: target.offset().top - 180 - $('thead').height()}, 0), 100);
            if (this.props.onClick !== null) this.props.onClick(elm);
        }
    }

    groups() {
        return this.props.columnGroups.length > 0 ? <></> : <tr>
            {Object.entries(this.props.columnGroups).map(([key, value], i) => <th style={{textAlign: "center"}} key={i}
                                                                                  colSpan={this.props.actions ? value + 1 : value}>{key}</th>)}
        </tr>
    }

    getAction(action, item) {
        if (action.onClick) {
            return <FontAwesomeIcon onClick={action.onClick.bind(this, item)}
                                    title={action["type"]} icon={action["icon"]}/>;
        } else {
            let href = action["href"];

            if (href.includes(":id")) {
                href = href.replace(":id", item[item["IDPropertyName"]]);
            }

            return <Link title={action["type"]} to={process.env.REACT_APP_ROOT + href}>
                <FontAwesomeIcon icon={action["icon"]}/>
            </Link>;
        }
    }

    renderAction(item) {
        if (this.props.actions) {
            return <td className={"actions"}>
                <div>
                    {this.props.actions.map((action, i) => {
                        return (
                            <div key={i}>
                                {this.getAction(action, item)}
                            </div>
                        );
                    })}
                </div>
            </td>;
        }
    }

    renderTooltip(children, value) {
        return <OverlayTrigger
            placement="auto-end"
            delay={{show: 250, hide: 400}}
            overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                    {value.toString()}
                </Tooltip>
            )}>
            {children}
        </OverlayTrigger>
    }

    render() {
        return (
            <>
                <div className={"tablesorter-export"}>
                    {this.props.addAction !== undefined && <Button variant={'outline-dark'} className={"addButton"} onClick={this.props.addAction}>+</Button>}
                    <ExportTable content={this.props.content}
                                 exportName={this.props.exportName ?? ""}
                                 columnsExport={this.props.columnsExport} />
                </div>

                <table ref={el => this.el = el} className={"tablesorter table table-bordered table-hover table-sm"} style={this.props.style}>
                    <thead className={this.hasStudent ? "hasStudent" : undefined}>
                    {this.groups()}
                    <tr>
                        {Object.entries(this.props.columns).map(([key, value], i) => <th key={i}>{key}</th>)}
                        {this.props.actions && <th key={this.props.columns.length}>Actions</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.content.map((item, i) => <tr key={i} data-id={item[item["IDPropertyName"]]}>
                        {Object.entries(this.props.columns).map(([key, value], j) => {
                            return (
                                <ConditionalWrapper
                                    condition={this.props.columnTooltips !== null && typeof this.props.columnTooltips[key] === "function" && this.props.columnTooltips[key](item) !== null}
                                    wrapper={c => this.renderTooltip(c, this.props.columnTooltips[key](item))}
                                    key={j}
                                >
                                    <td  style={this.props.columnStyles[key]}
                                         className={this.props.columnTooltips !== null && typeof this.props.columnClass[key] === 'function' ? this.props.columnClass[key](item) : this.props.columnClass[key]}>
                                        {typeof value === 'function' ? value(item) : item[value]}
                                    </td>
                                </ConditionalWrapper>
                            );
                        })}
                        {this.renderAction(item)}
                    </tr>)}
                    </tbody>
                </table>
            </>
        );
    }
}

TableSorter.propTypes = {
    columnStyles: PropTypes.object,
    columnClass: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.func)]),
    columnTooltips: PropTypes.objectOf(PropTypes.func),
    columnGroups: PropTypes.object,
    columnsExport: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
    columns: PropTypes.object.isRequired,


    content: PropTypes.array.isRequired,
    actions: PropTypes.array,
    addAction: PropTypes.func,
    onClick: PropTypes.func,
    sort: PropTypes.array,
};

TableSorter.defaultProps = {
    columnGroups: {},
    columnStyles: {},
    columnsExport: [],
    columnClass: "",
    columnTooltips: null,
    columns: {},

    content: [],
    onClick: null,
};

export default TableSorter;
import React from 'react';
import Security from "../../security";
import ScreenTable from "../../components/ScreenTable";
import {FormGroupement} from "../../forms/gestionAcademique/FormGroupement";

class Groupements extends React.PureComponent {

    static columns = {
        "ID": "GroupementUniteEnseignementID",
        "Libelle": "GroupementUniteEnseignementLibelle",
        "Nombre d'UE": i => i.GroupementsUniteEnseignementUnitesEnseignements.length,
        "Date de création": x => `${new Date(x.GroupementUniteEnseignementDateCreation).toLocaleDateString('fr-BE')} ${new Date(x.GroupementUniteEnseignementDateCreation).toLocaleTimeString('fr-BE')}` ,
    };

    componentWillUnmount() {
        this.setState = (state, callback) => {};
    }

    updateState(data) {
        this.setState({
            Groupements: data.result,
        })
    }

    render() {
        return (
            <section>
                <ScreenTable
                    url={`/screen/${Security.getScreenID("GroupementsUnitesEnseignement")}`}
                    updateState={this.updateState.bind(this)}
                    formEdit={<FormGroupement/>}
                    formNew={<FormGroupement/>}
                    Parameters={"UniteEnseignementID"}
                    columns={Groupements.columns}
                    columnsExport={["GroupementUniteEnseignementID"].concat(Object.values(Groupements.columns))}
                    content={this.state?.Groupements ?? []} />
            </section>
        );
    }
}

export default Groupements;
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/pro-solid-svg-icons";

function Aside() {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.body.classList.toggle('sidebar-mini', !isOpen);
    })

    return (
        <aside onClick={() => setIsOpen(!isOpen)}>
            <FontAwesomeIcon icon={faBars} style={{fontSize: "1.5rem", margin: "0"}} />
        </aside>
    );
}

export default Aside;
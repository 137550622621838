import React from 'react';
import ProtectedRoutes from "../components/ProtectedRoutes";
import {duotone, icon, light, regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {InitApp, NotFound} from "../screens";
import {
    Diplomes,
    Familles,
    NiveauxCours,
    Groupements,
    ProgrammeEffectif,
    FichesUE,
    TravauxPratiques,
    ListingEtudiants, GroupesParent
} from "../screens/gestionAcademique"
import {Etudiant, Etudiants} from "../screens/gestionEtudiants/etudiants";
import {PresenceCours} from "../screens/smartphones";
import {Welcome} from "../screens/unauthenticated";
import QRCode from "../screens/unauthenticated/QRCode";

export const authenticatedRoutes = [
    {
        path: `${process.env.REACT_APP_ROOT}`,
        first: true,
        exact: true,
        label: "Home",
        element: <InitApp/>,
        children: []
    },
    {
        path: `${process.env.REACT_APP_ROOT}gestionEtudiants`,
        label: "Gestion des étudiants",
        icon: solid('user-graduate'),
        isTitle: true,
        element: <ProtectedRoutes/>,
        children: [
            {
                path: "etudiants",
                label: "Etudiants",
                icon: [
                    {icon: solid('user'), transform: "shrink-11 left-5 up-1.5"},
                    {icon: light('search'), transform: "left-3.75 shrink-4"},
                ],
                element: <Etudiants/>,
                children: [
                    {
                        path: ":EtudiantID",
                        label: "Détail de l'inscription",
                        element: <Etudiant/>,
                        children: []
                    }
                ]
            },
        ],
    },
    {
        path: `${process.env.REACT_APP_ROOT}gestionAcademique`,
        label: "Gestion académique",
        icon: solid('cubes-stacked'),
        isTitle: true,
        element: <ProtectedRoutes/>,
        children: [
            {
                path: "diplomes",
                label: "Diplômes",
                icon: duotone('diploma'),
                element: <Diplomes/>,
                children: [],
            },
            {
                path: "niveauxCours",
                label: "Niveaux de cours",
                icon: regular('timeline'),
                element: <NiveauxCours/>,
                children: [],
            },
            {
                path: "programmeEffectif",
                label: "Programme effectif annuel",
                menu: "Prog. effectif annuel",
                icon: regular('list-tree'),
                element: <ProgrammeEffectif/>,
                children: [],
            },
            {
                path: "fichesUE",
                label: "Fiches UE",
                menu: "Fiches UE",
                icon: {icon: solid('file-circle-info'), transform: "shrink-2 right-1"},
                element: <FichesUE/>,
                children: [],
            },
            {
                path: "listing",
                label: "Listing étudiants",
                icon: solid('square-list'),
                element: <ListingEtudiants/>,
                children: [],
            },
            {
                path: "groupements",
                label: "Groupements",
                icon: regular('object-group'),
                element: <Groupements/>,
                children: [],
            },
            {
                path: "familles",
                label: "Familles",
                icon: regular('family'),
                element: <Familles/>,
                children: [],
            },
            {
                path: "groupesParent",
                label: "Groupes parent",
                icon: solid('users-line'),
                element: <GroupesParent/>,
                children: [],
            },
            {
                path: "travauxPratiques",
                label: "Travaux pratiques",
                icon: solid('users-viewfinder'),
                element: <TravauxPratiques/>,
                children: [],
            }
        ]
    },
    {
        path: `${process.env.REACT_APP_ROOT}smartphones`,
        label: "Smartphones",
        icon: solid('mobile-screen-button'),
        isTitle: true,
        element: <ProtectedRoutes/>,
        children: [
            {
                path: "presenceCours",
                label: "Prise de présence",
                icon: icon({name: 'file-signature', style: 'solid', family: 'sharp'}),
                element: <PresenceCours/>,
                children: [],
            },
        ]
    },
    {
        path: `${process.env.REACT_APP_ROOT}qr`,
        label: "QR Code",
        element: <QRCode/>,
        children: [
            {
                path: ":Code",
                element: <QRCode/>,
                children: []
            }
        ]
    },
    {
        path: "*",
        label: "404",
        element: <NotFound/>
    }
];

export const unauthenticatedRoutes = [
    {
        path:`${process.env.REACT_APP_ROOT}`,
        label: "Welcome",
        element: <Welcome/>
    },
    {
        path: `${process.env.REACT_APP_ROOT}qr`,
        label: "QR Code",
        element: <QRCode/>,
        children: [
            {
                path: ":Code",
                element: <QRCode/>,
                children: []
            }
        ]
    },
    {
        path: "*",
        label: "404",
        element: <NotFound/>
    }
];

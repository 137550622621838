import React, {createContext, useEffect, useReducer} from 'react';
import {initialApp, initialGlobal} from "../initialStates";
 import {authenticatedRoutes, unauthenticatedRoutes} from "../../routing/routes";
import {generateAppRoutes, pathTo} from "../../utils/routes";
import ThemeProvider from "./ThemeContext";

export const AppContext = createContext(initialGlobal);

export const AppProvider = ({children}) => {
    const [store, dispatch] = useReducer((state, action) => {
        return {...state, [action.key]: action.value}
    }, initialApp);

    useEffect(() => {
        let appAuthenticatedRoutes = generateAppRoutes(authenticatedRoutes);
        let appUnauthenticatedRoutes = generateAppRoutes(unauthenticatedRoutes);
        dispatch({
            key: "authenticatedRoutes",
            value: authenticatedRoutes,
        });
        dispatch({
            key: "unauthenticatedRoutes",
            value: unauthenticatedRoutes,
        });
        dispatch({
            key: "appAuthenticatedRoutes",
            value: appAuthenticatedRoutes,
        });
        dispatch({
            key: "appUnauthenticatedRoutes",
            value: appUnauthenticatedRoutes,
        });
        dispatch({
            key: "firstRoute",
            value: pathTo(appAuthenticatedRoutes.find(f => f.first)),
        });
        dispatch({
            key: "notFoundRoute",
            value: pathTo(appAuthenticatedRoutes.find(f => f.path === "/*")),
        });
    }, []);

    return (
        <ThemeProvider>
            <AppContext.Provider value={{store, dispatch}}>
                {children}
            </AppContext.Provider>
        </ThemeProvider>
    )
}
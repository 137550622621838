import React, {PureComponent} from 'react';
import ScreenTable from "../../components/ScreenTable";
import {ParametersContext} from "../../store/context/ParametersContext";
import {Col, FormLabel, Row} from "react-bootstrap";
import MySelect from "../../components/form/MySelect";
import axios from "axios";
import Security from "../../security";
import {FormGroupesParent} from "../../forms/gestionAcademique/FormGroupesParent";
import MyCheckbox from "../../components/form/MyCheckbox";

class GroupesParent extends PureComponent {

    static columns = {
        "Libelle": "GroupeParentActiviteApprentissageCode",
        "Niveau de cours lié": "NiveauCours",
        "Langue 2 liée": "CoursLangue2",
        "Bilingue": x => <MyCheckbox checked={x.GroupeParentActiviteApprentissageDiplomeBilingue} style={{display: "flex", justifyContent: "center"}}/>,
        "Nombre de places": "GroupeParentActiviteApprentissageNbPlaces",
        "Nombre d'activité d'apprentissage": x => x.LiensGroupeParentActiviteApprentissages.length,
    };
    static contextType = ParametersContext;

    constructor() {
        super();
        this.AnnacadRef = React.createRef();
        this.state = {AnneeAcademiqueID: 0};
    }

    async componentDidMount() {
        await this.context.updateParameters({
            Parameters: "CurrentAndNextAnneeAcademiqueID,NiveauCoursID,CoursLangue2ID",
            StaticValues: "WorkAnneeAcademiqueID",
        });
        if ((this.context?.parameters?.s?.WorkAnneeAcademiqueID ?? null) != null) {
            this.AnnacadRef.current.setValue(this.context.parameters?.p?.CurrentAndNextAnneeAcademiqueID?.find(f => f.value === this.context.parameters.s.WorkAnneeAcademiqueID));
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
        };
    }

    updateState(data) {
        this.setState(s => ({...s, GroupesParent: data.result}));
    }

    async fetch(option) {
        console.log(option);
        if (option !== undefined) {
            const {data} = await axios.get(`/screen/${Security.getScreenID("GroupesParent")}?AnneeAcademiqueID=${option.value}`);
            this.setState(s => ({...s, AnneeAcademiqueID: option.value}));
            this.updateState(data);
            await this.context.updateParameters({
                Parameters: `GroupeActiviteApprentissageAnneeAcademiqueID-${option.value}`,
            });
        }     
    }

    render() {
        return (
            <section>
                <Row style={{paddingBottom: "2rem"}}>
                    <Col>
                        <FormLabel>Année académique</FormLabel>
                        <MySelect
                            ref={this.AnnacadRef}
                            onChange={x => this.fetch(x)}
                            options={this.context?.parameters?.p?.CurrentAndNextAnneeAcademiqueID}
                            isClearable={true}
                        />
                    </Col>
                </Row>
                <ScreenTable
                    url={`/screen/${Security.getScreenID("GroupesParent")}?AnneeAcademiqueID=${this.state?.AnneeAcademiqueID ?? 0}`}
                    updateState={this.updateState.bind(this)}
                    formNew={<FormGroupesParent AnneeAcademique={this.state?.AnneeAcademiqueID ?? 0}/>}
                    formEdit={<FormGroupesParent AnneeAcademique={this.state?.AnneeAcademiqueID ?? 0}/>}
                    Parameters={"CoursLangue2ID,NiveauCoursID"}
                    columns={GroupesParent.columns}
                    columnsExport={["GroupeActiviteApprentissageID"].concat(Object.values(GroupesParent.columns))}
                    content={this.state?.GroupesParent ?? []}
                    columnGroups={{
                        [this.context.parameters?.p?.CurrentAndNextAnneeAcademiqueID?.find(f => f.value === (this.state?.AnneeAcademiqueID ?? 0))?.label ?? ""]: Object.keys(GroupesParent.columns).length,
                    }}
                />
            </section>
        );
    }
}

GroupesParent.contextType = ParametersContext;

export default GroupesParent;


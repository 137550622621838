import React, {useContext} from 'react';
import * as Yup from "yup";
import {TableInEdit} from "../utils/tables";
import {ParametersContext} from "../../store/context";
import {useForm} from "react-hook-form";
import {parseDateString} from "../../utils/functions";
import {Form, FormGroup, FormSelect} from "../../components/form";

FormPresenceCours.schema = Yup.object({
    "ActiviteApprentissageID": Yup.number().required().label("Activité d'apprentissage"),
    "PresenceCoursTypeID": Yup.number().nullable().label("Type"),
    "SessionID": Yup.number().nullable().label("Session"),
    "PresenceCoursLibelle": Yup.string().nullable().label("Libelle"),
    "PresenceCoursDate": Yup.date().nullable().default(undefined).transform(parseDateString)
        .typeError("Invalid Date").label("Date"),
}).required()

export function FormPresenceCours({data, onSave, AnneeAcademique}) {
    const {parameters} = useContext(ParametersContext);
    const {watch} = useForm();
    const watchFields = watch("PresenceCoursDate") // you can also target specific fields by their names

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    console.log(watchFields);
    
    return (
        <Form onSubmit={d => onSave(d)} defaultValues={data} schema={FormPresenceCours.schema}>
            <FormGroup name={"ActiviteApprentissageID"} label={"Activité d'apprentissage"} readOnly={!data.IsNew} type={FormSelect} options={parameters.p[`ActiviteApprentissageAnneeAcademiqueID-${AnneeAcademique}`]} rules={{required: true}}/>
            <FormGroup name={"PresenceCoursTypeID"} label={"Type"} readOnly={!data.IsNew} type={FormSelect} options={parameters.p.PresenceCoursTypeID}/>
            <FormGroup name={"SessionID"} label={"Session"} readOnly={!data.IsNew} type={FormSelect} options={parameters.p[`SessionAnneeAcademiqueID-${AnneeAcademique}`]}/>
            <FormGroup name={"PresenceCoursLibelle"} label={"Libelle"} />
            <FormGroup name={"PresenceCoursDate"} label={"Date"} />
            {
                !data.IsNew && <TableInEdit name={"MembrePersonnel"}
                                            columns={{"Membre du personnel": x => x.MembrePersonnel.Display}}
                                            columnsExport={Object.values({"Membre du personnel": x => x.MembrePersonnel.Display})}
                                            fieldName={"PresencesDelegations"}
                                            parameters={"MembrePersonnelID"}
                                            emptyValues={"PresenceDelegation"}
                                            reloadParameters={true}
                                            values={data.PresencesDelegations}/>
            }
        </Form>
    );
}
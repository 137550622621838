import React, {useContext} from "react";
import {Breadcrumb as Crumb} from "react-bootstrap";
import {Link,  useLocation} from "react-router-dom";
import {getCurrentRoute} from "../../utils/routes";
import {AppContext} from "../../store/context";
import {useIsAuthenticated} from "@azure/msal-react";

const Breadcrumb = () => {
    const {store} = useContext(AppContext);
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const current = getCurrentRoute(isAuthenticated ? store.authenticatedRoutes : store.unauthenticatedRoutes, location);
    const route = current === undefined ? [...store.firstRoute, ...store.notFoundRoute] : (current[0].first ? current : [...store.firstRoute, ...current]);

    return (
        <Crumb className={!route.title ? "wrapper" : undefined}>
            {route.map((crumb, index, breadcrumbs) => (
                <li className={`breadcrumb-item ${!crumb.element || index === breadcrumbs.length - 1 ? "active" : ""}`}
                    key={index}>
                    {crumb.isTitle || index === breadcrumbs.length - 1 ? crumb.label :
                        <Link to={crumb.path}>{crumb.label}</Link>}
                </li>
            ))}
        </Crumb>
    );
}

export default Breadcrumb;
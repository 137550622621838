import React, {PureComponent} from 'react';
import ScreenTable from "../../components/ScreenTable";
import {ParametersContext} from "../../store/context/ParametersContext";
import {Col, FormLabel, Row} from "react-bootstrap";
import MySelect from "../../components/form/MySelect";
import axios from "axios";
import Security from "../../security";
import {FormTravauxPratiques} from "../../forms/gestionAcademique/FormTravauxPratiques";

class TravauxPratiques extends PureComponent {

    static columns = {
        "Libelle": x => `${x.GroupeActiviteApprentissageCode} ${String(x.GroupeActiviteApprentissageNumero).padStart(2, '0')}`,
        "Activité d'apprentissage": x => x.ActiviteApprentissage.Display,
        "Plage horaire": "GroupeActiviteApprentissagePlageHoraire",
        "Nombre de places": "GroupeActiviteApprentissageNbPlaces",
        "Nombre d'étudiants": x => x.ProgrammesEtudiants.length,
        "Auto-Inscription": x => x.CountHasParent,
    };
    static contextType = ParametersContext;

    constructor() {
        super();
        this.AnnacadRef = React.createRef();
        this.state = {AnneeAcademiqueID: 0};
    }

    async componentDidMount() {
        await this.context.updateParameters({
            Parameters: "CurrentAndNextAnneeAcademiqueID",
            StaticValues: "WorkAnneeAcademiqueID",
        });
        if ((this.context?.parameters?.s?.WorkAnneeAcademiqueID ?? null) != null) {
            this.AnnacadRef.current.setValue(this.context.parameters?.p?.CurrentAndNextAnneeAcademiqueID?.find(f => f.value === this.context.parameters.s.WorkAnneeAcademiqueID));
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
        };
    }

    updateState(data) {
        this.setState(s => ({...s, TravauxPratiques: data.result}));
    }

    async fetch(option) {
        console.log(option);
        if (option !== undefined) {
            const {data} = await axios.get(`/screen/${Security.getScreenID("TravauxPratiques")}?AnneeAcademique=${option.value}`);
            this.setState(s => ({...s, AnneeAcademiqueID: option.value}));
            this.updateState(data);
            await this.context.updateParameters({
                Parameters: `ActiviteApprentissageAnneeAcademiqueID-${option.value}`,
            });
        }     
    }

    render() {
        return (
            <section>
                <Row style={{paddingBottom: "2rem"}}>
                    <Col>
                        <FormLabel>Année académique</FormLabel>
                        <MySelect
                            ref={this.AnnacadRef}
                            onChange={x => this.fetch(x)}
                            options={this.context?.parameters?.p?.CurrentAndNextAnneeAcademiqueID}
                            isClearable={true}
                        />
                    </Col>
                </Row>
                <ScreenTable
                    url={`/screen/${Security.getScreenID("TravauxPratiques")}?AnneeAcademique=${this.state?.AnneeAcademiqueID ?? 0}`}
                    updateState={this.updateState.bind(this)}
                    formNew={<FormTravauxPratiques AnneeAcademique={this.state?.AnneeAcademiqueID ?? 0}/>}
                    formEdit={<FormTravauxPratiques AnneeAcademique={this.state?.AnneeAcademiqueID ?? 0}/>}
                    Parameters={"AnneeAcademiqueID,LieuEnseignementID,ModaliteID,MembrePersonnelID"}
                    columns={TravauxPratiques.columns}
                    columnsExport={["GroupeActiviteApprentissageID"].concat(Object.values(TravauxPratiques.columns))}
                    content={this.state?.TravauxPratiques ?? []}
                    columnGroups={{
                        [this.context.parameters?.p?.CurrentAndNextAnneeAcademiqueID?.find(f => f.value === (this.state?.AnneeAcademiqueID ?? 0))?.label ?? ""]: Object.keys(TravauxPratiques.columns).length,
                    }}
                />
            </section>
        );
    }
}

TravauxPratiques.contextType = ParametersContext;

export default TravauxPratiques;


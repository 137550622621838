export const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_MSAL_CLIENTID}`,
        authority: `${process.env.REACT_APP_MSAL_AUTHORITY}`,
        redirectUri: `${process.env.REACT_APP_ENDPOINT}${process.env.REACT_APP_ROOT}`,
        postLogoutRedirectUri: `${process.env.REACT_APP_ENDPOINT}`,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const graphScopes = {
    scopes: ["User.Read"]
};

export const apiScopes = {
    scopes: [`${process.env.REACT_APP_API_SCOPE}`]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
/*
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
*/
import React from 'react';
import {Controller} from "react-hook-form";
import MySelect from "./MySelect";
import PropTypes from "prop-types";

const FormSelect = React.forwardRef(({name, options = [], control, readOnly}, ref) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({field: {value, onChange, onBlur}}) => {
                return <MySelect
                    ref={ref}
                    disabled={readOnly}
                    control={control}
                    options={options}
                    value={options.filter(o => o.value === value)}
                    defaultValue={options.filter(o => o.value === value)}
                    placeholder={"Select..."}
                    onChange={o => onChange(o.value)}
                    onBlur={onBlur}
                />
            }}/>
    );
});

FormSelect.PropsTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
};

export default FormSelect;
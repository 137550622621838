import React, {useState} from 'react';
import ScreenTable from "../../../components/ScreenTable";

Inscriptions.columns = {
    "Année académique": x => `${x.GroupeActiviteApprentissageCode} ${String(x.GroupeActiviteApprentissageNumero).padStart(2, '0')}`,
    "Statut": x => x.ActiviteApprentissage?.Display,
    "Diplôme": "GroupeActiviteApprentissagePlageHoraire",
    "Niveau d'études": "GroupeActiviteApprentissageNbPlaces",
    "Minerval payé": x => x.ProgrammesEtudiants?.length,
    "Boursier": x => x.CountHasParent,
    "Total crédits délibérés": x => x.CountHasParent,
    "Moyenne générale": x => x.CountHasParent,
    "Décision jury": x => x.CountHasParent,
    "Date de délibération": x => x.CountHasParent,
};

const url = "/screen/1/14053";

export default function Inscriptions() {
    const [value, setValue] = useState([]);

    return (
        <section>
            <ScreenTable
                url={`${url}`}
                updateState={x => setValue(x.result)}
                Parameters={"AnneeAcademiqueID,LieuEnseignementID,ModaliteID"}
                columns={Inscriptions.columns}
                columnsExport={["GroupeActiviteApprentissageID"].concat(Object.values(Inscriptions.columns))}
                content={value}
            />
        </section>
    );
}

import React, {useEffect, useRef} from 'react';
import {Collapse} from "react-bootstrap";
import {MySelect} from "../../../components/form";

const AddBySelect = ({show, ...props}) => {

    let ref = useRef();

    useEffect(() => {
        if (show) {
            ref.current.clearValue();
            ref.current.openMenu();
        }
    }, [show])

    return (
        <>
            <Collapse in={show}>
                <div style={{marginTop: ".5rem"}}>
                    <MySelect ref={ref} {...props}/>
                </div>
            </Collapse>
        </>
    );
};

export default AddBySelect;
import React from 'react';
import * as Yup from "yup";
import axios from "axios";
import {FormGroup} from "../../components/form";

FieldsProgrammeEffectif.schema = Yup.object({
    Code: Yup.string().required().min(3).test('check', 'Ce code est déjà connu de Meteor', function (value) {
        return new Promise((resolve) => {
            axios.post('/programmeEffectif/check', { Code: value }).then(() => {
                resolve(true)
            }).catch(() => {
                resolve(false)
            })
        })
    }).label("Code"),
    NbAA: Yup.number().required().integer().positive().min(1).label("Nombre d'AA"),
}).required()

function FieldsProgrammeEffectif() {
    return <>
        <FormGroup name={"Code"} label={"Code"} rules={{required: true}}/>
        <FormGroup name={"NbAA"} defaultValue={1} label={"Nombre d'AA"} rules={{required: true}}/>
    </>;
}

export default FieldsProgrammeEffectif;
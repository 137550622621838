import React, {PureComponent} from 'react';
import ScreenTable from "../../components/ScreenTable";
import {FormPresenceCours} from "../../forms/smartphones/FormPresenceCours";
import {ParametersContext} from "../../store/context/ParametersContext";
import {Col, FormLabel, Row} from "react-bootstrap";
import MySelect from "../../components/form/MySelect";
import axios from "axios";
import Security from "../../security";

class PresenceCours extends PureComponent {

    static columns = {
        "Date": x => x.PresenceCoursType === "Examen" ? x.Session : (x.PresenceCoursDate !== null ? `${new Date(x.PresenceCoursDate).toLocaleDateString('fr-BE')}` : ''),
        "Activité d'apprentissage": x => x.ActiviteApprentissage.Display,
        "Type": "PresenceCoursType",
        "Libelle": "PresenceCoursLibelle",
        "Nb de délégations" : x => x.PresencesDelegations.length
    };
    static contextType = ParametersContext;

    constructor() {
        super();
        this.AnnacadRef = React.createRef();
        this.state = {AnneeAcademiqueID: 0};
    }

    async componentDidMount() {
        await this.context.updateParameters({
            Parameters: "PreviousCurrentAndNextAnneeAcademiqueID",
            StaticValues: "WorkAnneeAcademiqueID",
        });
        if ((this.context?.parameters?.s?.WorkAnneeAcademiqueID ?? null) != null) {
            this.AnnacadRef.current.setValue(this.context.parameters?.p?.PreviousCurrentAndNextAnneeAcademiqueID?.find(f => f.value === this.context.parameters.s.WorkAnneeAcademiqueID));
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
        };
    }

    updateState(data) {
        this.setState(s => ({...s, PresenceCours: data.result}));
    }

    async fetch(option) {
        if (option !== undefined) {
            const {data} = await axios.get(`/screen/${Security.getScreenID("PresenceCours")}?AnneeAcademique=${option.value}`);
            this.setState(s => ({...s, AnneeAcademiqueID: option.value}));
            this.updateState(data);
            await this.context.updateParameters({
                Parameters: `SessionAnneeAcademiqueID-${option.value},ActiviteApprentissageAnneeAcademiqueID-${option.value}`,
            });
        }
    }

    render() {
        return (
            <section>
                <Row style={{paddingBottom: "2rem"}}>
                    <Col>
                        <FormLabel>Année académique</FormLabel>
                        <MySelect
                            ref={this.AnnacadRef}
                            onChange={x => this.fetch(x)}
                            options={this.context?.parameters?.p?.PreviousCurrentAndNextAnneeAcademiqueID}
                            isClearable={true}
                        />
                    </Col>
                </Row>
                <ScreenTable
                    url={`/screen/${Security.getScreenID("PresenceCours")}?AnneeAcademique=${this.state?.AnneeAcademiqueID ?? 0}`}
                    updateState={this.updateState.bind(this)}
                    formNew={<FormPresenceCours AnneeAcademique={this.state?.AnneeAcademiqueID ?? 0}/>}
                    formEdit={<FormPresenceCours AnneeAcademique={this.state?.AnneeAcademiqueID ?? 0}/>}
                    Parameters={`PresenceCoursTypeID`}
                    columns={PresenceCours.columns}
                    columnsExport={["GroupeActiviteApprentissageID"].concat(Object.values(PresenceCours.columns))}
                    content={this.state?.PresenceCours ?? []}
                />
            </section>
        );
    }
}

PresenceCours.contextType = ParametersContext;

export default PresenceCours;


import React from 'react';
import {Controller, useFormContext} from "react-hook-form";
import MyCheckbox from "./MyCheckbox";
import PropTypes from "prop-types";

const FormCheckbox = React.forwardRef(({name, checked, onChange: myOnChange = null, ...rest}, ref) => {
    const { control } = useFormContext();
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={checked}
            render={({field: {value, onChange}}) => {
                return <MyCheckbox
                    {...rest}
                    ref={ref}
                    onChange={(e) => {
                        onChange(e);
                        if (myOnChange != null) myOnChange(!value);
                    }}
                    checked={value}/>
            }}/>
    );
});

FormCheckbox.PropsTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
};

export default FormCheckbox;
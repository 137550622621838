import React, {useContext} from "react";
import {Col, Image, Row} from "react-bootstrap";
import {faHashtag, faSpinner, faUser} from "@fortawesome/pro-solid-svg-icons";
import IconTitle from "../IconTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GlobalContext} from "../../store/context";
import {emptyPhoto} from "../../store/initialStates";

const Student = React.memo(function Student() {
    const {store} = useContext(GlobalContext);

    return (
        <section id={"Student"}>
            {Object.keys(store.etudiant).length === 0
                ?
                <div>
                    <FontAwesomeIcon size={"5x"} style={{padding: "1rem"}} icon={faSpinner} pulse={true}/>
                </div>
                :
                <Row className="align-items-center">
                    <Col style={{maxWidth: "10rem", display: "flex", justifyContent: "center"}}>
                        <Image
                            src={store.etudiant.PersonnePhoto === null ? emptyPhoto : store.etudiant.PersonnePhoto}
                            alt={"PhotoEtudiant"} className={store.etudiant.PersonnePhoto === null ? "empty" : ""}/>
                    </Col>
                    <Col style={{maxWidth: "20rem"}}>
                        <IconTitle type={"h5"} icon={faHashtag} classIcon={'fa-sm'} classContent={"px-1"}
                                   content={store.etudiant.EtudiantMatricule}/>
                        <IconTitle type={"h5"} icon={faUser} classIcon={'fa-sm'} classContent={"px-1"}
                                   content={<><b>{store.etudiant.PersonneNom}</b> {store.etudiant.PersonnePrenom}</>}/>

                    </Col>
                    <Col xs={"auto"}>
                        <IconTitle type={"h5"} icon={faHashtag} classIcon={'fa-sm'} classContent={"px-1"}
                                   content={store.etudiant.EtudiantMatricule}/>
                        <IconTitle type={"h5"} icon={faUser} classIcon={'fa-sm'} classContent={"px-1"}
                                   content={store.etudiant.Etudiant}/>

                    </Col>
                </Row>}
        </section>
    );
});

export default Student;
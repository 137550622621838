import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedin, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import React from "react";

export default function Social() {
    
    return <div>
        <div className="social">
            <a className={"text-white pr-2"}
               href="https://files.ichec.be/ICHEC-Student-Politique-Vie-Privee.pdf"
               target="_blank"
               rel="noopener noreferrer">
                <small>Politique de vie privée</small>
            </a>
            <a href="src/components/visual/Social"
               target="_blank"
               rel="noopener noreferrer">
                <FontAwesomeIcon className="fa-fw" icon={faFacebookF}/>
            </a>
            <a href="https://twitter.com/IchecBrussels"
               target="_blank"
               rel="noopener noreferrer">
                <FontAwesomeIcon className="fa-fw" icon={faTwitter}/>
            </a>
            <a href="src/components/visual/Social"
               target="_blank"
               rel="noopener noreferrer">
                <FontAwesomeIcon className="fa-fw" icon={faLinkedin}/>
            </a>
            <a href="src/components/visual/Social"
               target="_blank"
               rel="noopener noreferrer">
                <FontAwesomeIcon className="fa-fw" icon={faInstagram}/>
            </a>
            <a href="https://www.youtube.com/channel/UCG9stSs0SzxapWXmIIxua0g"
               target="_blank"
               rel="noopener noreferrer">
                <FontAwesomeIcon className="fa-fw" icon={faYoutube}/>
            </a>
        </div>
    </div>;
}
import React, {useContext} from 'react';
import axios from "axios";

import $ from 'jquery';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoon, faSunBright} from "@fortawesome/pro-solid-svg-icons";
import {Col, Form, FormLabel, Row} from "react-bootstrap";
import {EditContext, ParametersContext, GlobalContext, ThemeContext} from "../../store/context";
import Security from "../../security";
import {MyCheckbox, MySelect} from "../../components/form";
import {FormProgrammeEffectif} from "../../forms/gestionAcademique";
import {TableSorter} from "../../components";

const ProgrammeEffectifContext = props => {
    const edit = useContext(EditContext);
    const params = useContext(ParametersContext);
    const global = useContext(GlobalContext);
    const theme = useContext(ThemeContext);
    return <ProgrammeEffectif EditPanel={edit} ParametersPanel={params} GlobalPanel={global} {...props} Theme={theme} />
}

export default ProgrammeEffectifContext;

class ProgrammeEffectif extends React.Component {

    static columns = {
        "Code": "Code",
        "UE": "LibelleUE",
        "AA": "LibelleAA",
        "ECTS": "NbECTS",
        "Heures": "NbHeures",
        "Q1": "Q1",
        "Q2": "Q2",
        "AA neutralisables?": "AANeutralisable",
        "%": "Ponderation",
        "Modalité": "ModaliteID",
        "Enseignants": "Intervenants",
        "Site": "LieuEnseignementID",
    };

    static columnsExport = [
        "ProgrammeEffectifID",
        "Code",
        "LibelleUE",
        "LibelleAA",
        "NbECTS",
        "NbHeures",
        "AAChoixMultiples",
        "Q1", "Q2",
        "Ponderation",
        "Modalite",
        "Enseignants",
        "Site"
    ];

    constructor() {
        super();
        this.AnnacadRef = React.createRef();
    }

    async componentDidMount() {
        await this.props.ParametersPanel.updateParameters({
            Parameters: "PreviousCurrentAndNextAnneeAcademiqueID,LieuEnseignementID,ModaliteID,NiveauCoursID,MembrePersonnelID,FamilleUniteEnseignementID,GroupementUniteEnseignementID",
            StaticValues: "WorkAnneeAcademiqueID",
            EmptyValues: "NiveauCoursUniteEnseignement,Prerequis,Corequis,Incompatible,FamilleUniteEnseignementUniteEnseignement,GroupementUniteEnseignementUniteEnseignement"
        });

        if ((this.props.ParametersPanel?.parameters?.s?.WorkAnneeAcademiqueID ?? null) != null) {
            this.AnnacadRef.current.setValue(this.props.ParametersPanel.parameters?.p?.PreviousCurrentAndNextAnneeAcademiqueID?.find(f => f.value === this.props.ParametersPanel.parameters.s.WorkAnneeAcademiqueID));
        }
    }

    async fetch(option) {
        this.props.EditPanel.close();
        if (option !== undefined) {
            const {data} = await axios.get(`/programmeEffectif/${option.value}?${this.props.GlobalPanel.store.ProgrammeEffectifCriteria.map(m => `${m}=1`).join('&')}`);
            this.prepareData(data, option);
            await this.props.ParametersPanel.updateParameters({
                Parameters: `UniteEnseignementAnneeAcademiqueID-${option.value}`,
            });
        }        
    }

    prepareData(data, option) {
        data.result.forEach(x => {
            x.Intervenants = x.Intervenants.sort((a, b) => b.IntervenantResponsable - a.IntervenantResponsable);
            x.Tooltips = {};
            Object.entries(x).forEach(([key]) => {
                if (key !== "Precedents") {
                    if (key !== "Intervenants") {
                        x[`_is${key}Changed`] = x.Precedents?.some(s => s[key] !== x[key]);
                        x.Tooltips[key] = x[`_is${key}Changed`] ? Array.from(x.Precedents, y => y[key]) : "";
                    } else if (x.Precedents[0]?.Intervenants !== undefined) {
                        x.Tooltips.Intervenants = Array.from(x.Precedents[0]?.Intervenants, y => y.MembrePersonnel.Display);
                        let enseignants = Array.from(x.Intervenants, y => y.MembrePersonnel.Display);
                        x[`_isIntervenantsChanged`] = !enseignants.every(s => x.Tooltips.Intervenants.includes(s)) || enseignants.length < x.Tooltips.Intervenants.length;
                    }
                }
            });
            x.Enseignants = x.Intervenants.map(y => y.MembrePersonnel?.Display).join(" / ");
            x.Modalite = this.props.ParametersPanel?.parameters?.p?.ModaliteID?.find(f => f.value === x.ModaliteID)?.label;
            x.Site = this.props.ParametersPanel?.parameters?.p?.LieuEnseignementID?.find(f => f.value === x.LieuEnseignementID)?.label;
            ["Prerequis", "PrerequisMaitres", "Corequis", "CorequisMaitres", "Incompatibles", "IncompatiblesMaitres", "NiveauxCoursUnitesEnseignement"].map(m => x[m] = x[m] ?? []);
        });
        this.setState({
            ProgrammeEffectif: data.result,
            AnneeAcademiqueID: option.value
        });
    }

    componentWillUnmount() {
        this.setState = () => {
        };
    }

    animateTo(target) {
        $('html,body').animate({scrollTop: target.offset().top - 230}, 0);
    }

    onCreate() {
        $('table').find('.table-secondary').removeClass();
        this.props.EditPanel.update(true, `Création d'un programme effectif annuel`,
            <FormProgrammeEffectif data={{IsNew: true}} onSave={data => this.onSave(data)}/>);
    }

    async onSave(d) {
        d = Object.fromEntries(Object.entries(d).filter(([key]) => key.charAt(0) !== '_'));

        if (d.IsUE) {
            ["PrerequisMaitres", "CorequisMaitres", "IncompatiblesMaitres"].map(m => {
                let t = m.split('Maitres')[0];
                let t1 = t;

                t = t.includes('requis') ? t : t.slice(0, -1);

                d[m].map(x => {
                    if (x[`${t}ID`] === 0) {
                        x.UniteEnseignementMaitreID = d.ProgrammeEffectifID;
                        x.UniteEnseignementRequisID = x.UniteEnseignementRequis.ID;

                        if (!t.startsWith("Pre")) {
                            let y = {...x};
                            y.UniteEnseignementRequisID = x.UniteEnseignementMaitreID;
                            y.UniteEnseignementMaitreID = x.UniteEnseignementRequisID;

                            d[t1].push(y);
                        }
                    }

                    return x;
                });

                return d;
            });
        }

        console.log(d);

        const {data} = await axios.post(`/programmeEffectif/${this.state.AnneeAcademiqueID}?${this.props.GlobalPanel.store.ProgrammeEffectifCriteria.map(m => `${m}=1`).join('&')}`, d);
        this.prepareData(data, {value: this.state.AnneeAcademiqueID});

        if (d.IsNew) {
            setTimeout(() => {
                this.animateTo($(`td:contains('${d.Code}')`).first());
            }, 1000);
        }

        this.props.EditPanel.close();
    }

    /*
    update(v) {
        this.setState({AnneeAcademiqueID: v}, () => {
            console.log(this.state);
        });
    }
    */

    async onClick(elm) {
        
        console.log(elm.target);
        
        if (elm.target === elm.currentTarget) return;
        
        let selected = this.state.ProgrammeEffectif.find(f => f[f["IDPropertyName"]] === parseInt(elm.currentTarget.dataset.id));

        const requests = [
            "NiveauxCoursUnitesEnseignement",
            "Prerequis",
            "Corequis",
            "Incompatibles",
            "FamillesUniteEnseignementUnitesEnseignement",
            "GroupementsUniteEnseignementUnitesEnseignement",
        ].map((m, k) => axios.get(`/screen/${Security.getScreenID(m)}?UniteEnseignement${k !== 0 && k <= 3 ? "Maitre" : ""}ID=${parseInt(elm.currentTarget.dataset.id)}`, {headers: {"X-ScreenName": m}}));

        await axios.all(requests).then(r => {
            r.forEach(m => {
                selected[m.config.headers["X-ScreenName"] + (["Prerequis", "Corequis", "Incompatibles"].includes(m.config.headers["X-ScreenName"]) ? "Maitres" : "")] = m.data.result;
            });
            console.log(selected);
        });

        this.props.EditPanel.update(true, `Modifier l'${selected.IsUE ? 'UE' : 'AA'} ${selected.Code}`,
            <FormProgrammeEffectif data={selected} onSave={data => this.onSave(data)} onDelete={true}/>);
    }

    getColumns() {
        let col = {...ProgrammeEffectif.columns};
        col.Q1 = i => <MyCheckbox checked={i.Q1} style={{display: "flex", justifyContent: "center"}}/>;
        col.Q2 = i => <MyCheckbox checked={i.Q2} style={{display: "flex", justifyContent: "center"}}/>;
        col["AA neutralisables?"] = i => <MyCheckbox checked={i.AANeutralisable} style={{display: "flex", justifyContent: "center"}}/>;
        col.Modalité = i => {
            let val = i.Modalite;
            let r;
            switch (val) {
                case "Jour":
                    r = <FontAwesomeIcon icon={faSunBright}/>;
                    break;
                case "Horaire Décalé":
                    r = <FontAwesomeIcon icon={faMoon}/>;
                    break;
                default:
                    r = val;
                    break;
            }
            return <><span className={"d-none"}>{val}</span>{r}</>;
        };
        col.Enseignants = i => {
            return i.Intervenants.map((x, rank) => {
                return <React.Fragment key={rank}>
                    {x.IntervenantResponsable ?
                        <b>{x.MembrePersonnel?.Display}</b> : x.MembrePersonnel?.Display} {i.Intervenants.length > ++rank ? " / " : ""}
                </React.Fragment>
            })
        };
        col["%"] = i => i["Ponderation"] !== null ? parseFloat(i["Ponderation"]).toFixed(1): "";
        col.Site = i => this.props.ParametersPanel?.parameters?.p?.LieuEnseignementID?.find(f => f.value === i.LieuEnseignementID)?.label.substring(0, 7) ?? "";
        return col;
    }

    getTooltips() {
        let col = {};
        Object.entries(ProgrammeEffectif.columns).forEach(([key, value]) => {
            col[key] = i => i[`_is${value}Changed`] ? i.Tooltips[value] : null
        });
        return col;
    }

    getClass() {
        let col = {};
        Object.entries(ProgrammeEffectif.columns).forEach(([key, value]) => {
            col[key] = i => i[`_is${value}Changed`] ? "bg-purple" : (i["Precedents"].length === 0 ? "bg-success" : undefined)
        });
        return col;
    }
    
    render() {
        return (
            <section>
                <style>
                    {`
                    td {--bg-opacity: ${this.props.Theme.theme === 'dark' ? '.5' : '.15'} !important;}
                    .table-secondary > .bg-success {--success-rgb: 222,250,245; --bg-opacity:1 !important;}
                    `}
                </style>
                {this.renderCriteria()}
                <TableSorter
                    onClick={this.onClick.bind(this)}
                    addAction={this.onCreate.bind(this)}
                    sort={[[0, 0]]}
                    columnGroups={{
                        [this.props.ParametersPanel.parameters?.p?.PreviousCurrentAndNextAnneeAcademiqueID?.find(f => f.value === (this.state?.AnneeAcademiqueID ?? 0))?.label ?? ""]: Object.keys(ProgrammeEffectif.columns).length,
                    }}
                    columnTooltips={this.getTooltips()}
                    columnClass={this.getClass()}
                    columnStyles={{
                        "Heures": {verticalAlign: "middle", textAlign: "right"},
                        "ECTS": {verticalAlign: "middle", textAlign: "right"},
                        "AA neutralisables?": {verticalAlign: "middle"},
                        "Q1": {verticalAlign: "middle"},
                        "Q2": {verticalAlign: "middle"},
                        "%": {verticalAlign: "middle", textAlign: "right"},
                        "Modalité": {verticalAlign: "middle", textAlign: "center"},
                    }}
                    columnsExport={ProgrammeEffectif.columnsExport}
                    columns={this.getColumns()}
                    content={this.state?.ProgrammeEffectif ?? []}/>
            </section>
        );
    }

    renderCriteria() {
        return <>
            <Row style={{paddingBottom: "0.75rem"}}>
                <Col>
                    <FormLabel>Année académique</FormLabel>
                    <MySelect
                        ref={this.AnnacadRef}
                        onChange={x => this.fetch(x)}
                        options={this.props.ParametersPanel?.parameters?.p?.PreviousCurrentAndNextAnneeAcademiqueID}
                    />
                </Col>
            </Row>
            <Row style={{paddingBottom: "2rem"}}>
                <Col xs={12} sm={4} lg={1}>
                    <FormLabel>Avec</FormLabel>
                    <div>
                        {['UE', 'AA'].map((m, k) => this.getCriteriaRender(m, k))}
                    </div>
                </Col>

                <Col xs={12} sm={4} lg={1}>
                    <FormLabel>Modalité</FormLabel>
                    <div>
                        {['Jour', 'HD', "Alternance", "Au choix"].map((m, k) => this.getCriteriaRender(m, k))}
                    </div>
                </Col>

                <Col>
                    <FormLabel>Code commençant par (multiples séparés par une virgule)</FormLabel>
                    <div>
                        <Form.Control name={"codeStartsWith"} defaultValue={"11,12,13"} onChange={x => {
                            const criteria = `${x.currentTarget.value.split(",").join("%,")}%`;
                            const currentCriteria = this.props.GlobalPanel.store.ProgrammeEffectifCriteria.filter(a => !a.endsWith("%"));
                            criteria.split(',').forEach((c) => currentCriteria.push(c));
                            this.props.GlobalPanel.dispatch({
                                key: "ProgrammeEffectifCriteria",
                                value: currentCriteria
                            });
                            setTimeout(() => this.fetch(this.AnnacadRef.current.getValue()[0]), 1000);
                        }}/>
                    </div>
                </Col>


            </Row>
        </>;
    }

    getCriteriaRender(m, k) {
        return <MyCheckbox key={k} label={m}
                           checked={this.props.GlobalPanel.store.ProgrammeEffectifCriteria.includes(m)}
                           onChange={x => {
                               this.props.GlobalPanel.dispatch({
                                   key: "ProgrammeEffectifCriteria",
                                   value: this.props.GlobalPanel.store.ProgrammeEffectifCriteria.includes(m) ? this.props.GlobalPanel.store.ProgrammeEffectifCriteria.filter(a => a !== m) : [...this.props.GlobalPanel.store.ProgrammeEffectifCriteria, m]
                               });
                               setTimeout(() => this.fetch(this.AnnacadRef.current.getValue()[0]), 250);
                           }} edit={true} isFilter={true}/>;
    }
}

import React, {useContext, useEffect, useState} from 'react';
import {Accordion} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useLocation} from "react-router-dom";
import {getCurrentRoute} from "../../utils/routes";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {AppContext} from "../../store/context";

const Sidebar = () => {
    const {store} = useContext(AppContext);
    let [activeKey, setActiveKey] = useState(0);

    const location = useLocation();
    const route = getCurrentRoute(store.authenticatedRoutes, location);
    let mapRoute = route?.map(m => m.realPath) ?? [];
    let params = route?.slice(-1)[0].params ?? [];
    let sidebarRoutes = store.appAuthenticatedRoutes?.filter(x => x.isTitle);
    let currentActiveKey = sidebarRoutes?.map(x => x.path).indexOf(mapRoute[0]) ?? 0;

    useEffect(() => setActiveKey(currentActiveKey), [currentActiveKey])

    const icon = (route) => {
        let array = route.icon ?? [{icon: solid('question')}];
        array = !Array.isArray(array) ? [array] : array;
        
        let result = array.map((x, y) => {
            let o = Array.isArray(x.icon) ? {icon: x}: x;

            return <FontAwesomeIcon key={y}
                                    className={`fa-fw ${o.icon?.iconName === "question" ? "fa-flip" : ""}`}
                                    {...o}/>
        });

        return <>
            {array.length > 1 ? <span className={"fa-layers fa-fw"}>{result}</span> : <>{result}</>}
            <span>{route.menu ?? route.label}</span>
        </>
    }

    const node = (route, key) => {
        let parameters = Object.keys(params).map(x => `:${x}`).join('|');

        let link = route.path;
        Object.entries(params).map(([key, value]) => link = link.replace(`:${key}`, value));

        let children = route.children?.map(x => {
            if (x.realPath.includes('/:') && (parameters.length === 0 || x.realPath.match(new RegExp(parameters)).length === 0)) return null;
            return node(x, ++key);
        });

        return (
            <React.Fragment key={key}>
                <Link to={link}>
                    <li className={mapRoute.at(-1) === route.realPath ? "active" : undefined}>
                        {icon(route)}
                    </li>
                </Link>
                {children}
            </React.Fragment>
        );
    };

    return (
        <aside>
            <Accordion as="ul" bsPrefix=" " activeKey={activeKey}>
                {sidebarRoutes.map((i, j) => {
                    return (i.clickable
                            ?
                            <li className={mapRoute.at(-1) === i.path ? "active" : undefined} key={j}>
                                <Link to={i.path}>
                                    {icon(i)}
                                </Link>
                            </li>
                            :
                            <Accordion.Item as="li" bsPrefix=" " eventKey={j} key={j}>
                                <Accordion.Button as="div" bsPrefix=" " onClick={() => setActiveKey(j)}>
                                    {icon(i)}
                                </Accordion.Button>
                                <Accordion.Collapse as="ul" eventKey={j}>
                                    <>
                                        {i.children?.map((x, y) => node(x, y))}
                                    </>
                                </Accordion.Collapse>
                            </Accordion.Item>
                    );
                })}
            </Accordion>
        </aside>
    );
}

export default Sidebar;
import React, {Component} from 'react';
import MySelect from "../../components/form/MySelect";
import {Col, FormLabel, Row} from "react-bootstrap";
import axios from "axios";
import {ParametersContext} from "../../store/context/ParametersContext";
import TableSorter from "../../components/TableSorter";
import Security from "../../security";

class ListingEtudiants extends Component {

    static columns = {
        "Matricule" : "EtudiantMatricule",
        "Etudiant": "Etudiant",
        "Diplome": "DiplomeLibelle",
        "Niveau de cours": "NiveauCoursLibelle",
        "Email": "Email"
    };

    constructor(props) {
        super(props);
        this.state = {
            ActivitesApprentissage: [],
            Listing: [],
            dispense: false,
        };
        this.UERef = React.createRef();
        this.AARef = React.createRef();
    }

    async componentDidMount() {
        this.context.updateParameters({
            Parameters: "PreviousCurrentAndNextUniteEnseignementID",
        });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {};
    }

    async fetch(id, isAA = false) {
        const {data} = await axios.get(`/screen/${Security.getScreenID("ListingEtudiants")}?Deleted=false&Desinscription=false&Report=false&Transfert=false&Dispense=false&Refus=false&${isAA ? "ActiviteApprentissageID" : "UniteEnseignementID"}=${id}`);

        this.setState({
            Listing: data.result,
        })
    }

    async updateUE(data) {
        await this.context.updateParameters({
            Parameters: `AAFromUEID-${data.value}`,
        });

        this.AARef.current.clearValue();

        this.setState({
            ActivitesApprentissage: this.context.parameters?.p[`AAFromUEID-${data.value}`],
        });
    }

    async updateAA(data) {
        if (data !== null) {
            this.fetch(data.value, true);
        } else {
            this.fetch(this.UERef.current.state.selectValue[0].value);
        }
    }

    render() {
        return (
            <div>
                <section style={{marginBottom: "2rem"}}>
                    <Row>
                        <Col>
                            <FormLabel>Unité d'enseignement</FormLabel>
                            <MySelect
                                ref={this.UERef}
                                onChange={this.updateUE.bind(this)}
                                options={this.context.parameters?.p?.PreviousCurrentAndNextUniteEnseignementID}
                            />
                        </Col>
                        <Col>
                            <FormLabel>Activité d'apprentissage</FormLabel>
                            <MySelect
                                ref={this.AARef}
                                onChange={this.updateAA.bind(this)}
                                options={this.state.ActivitesApprentissage}
                                isClearable={true}
                            />
                        </Col>
                    </Row>
{/*
                    <Row style={{marginTop: "1rem"}}>
                        <Col>
                            <MyCheckbox name={"IsMemoire"}
                                        onChange={_ => this.setState(p => ({dispense: !p.dispense}))}
                                        label={"Est dispensé"}
                                        checked={this.state.dispense}
                                        edit={true}/>
                        </Col>
                        <Col>
                            <MyCheckbox name={"IsMemoire"}
                                        onChange={_ => this.setState(p => ({dispense: !p.dispense}))}
                                        label={"Report"}
                                        checked={this.state.dispense}
                                        edit={true}/>
                        </Col>
                        <Col>
                            <MyCheckbox name={"IsMemoire"} label={"Est dispensé"} edit={true}/>
                        </Col>
                        <Col>
                            <MyCheckbox name={"IsMemoire"} label={"Est dispensé"} edit={true}/>
                        </Col>
                    </Row>
*/}
                    <TableSorter
                        content={this.state.Listing ?? []}
                        columnGroups={{
                            [this.AARef?.current?.state?.selectValue[0]?.label ?? this.UERef?.current?.state?.selectValue[0]?.label ?? ""] : Object.keys(ListingEtudiants.columns).length,
                        }}
                        columns={ListingEtudiants.columns}
                        columnsExport={Object.values(ListingEtudiants.columns)}
                        exportName={this.AARef?.current?.state?.selectValue[0]?.label ?? this.UERef?.current?.state?.selectValue[0]?.label ?? ""}
                    />
                </section>

            </div>
        );
    }
}

ListingEtudiants.contextType = ParametersContext;

export default ListingEtudiants;
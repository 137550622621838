import React, {createContext, useReducer} from 'react';
import {initialGlobal} from "../initialStates";
import axios from "axios";

export const GlobalContext = createContext(initialGlobal);

export const GlobalProvider = ({children}) => {
    const [store, dispatch] = useReducer((state, action) => {
        let result = {...state, [action.key]: action.value};
        axios.post("/preferencies", result);
        return result;
    }, initialGlobal);

    return (
        <GlobalContext.Provider value={{store, dispatch}}>
            {children}
        </GlobalContext.Provider>
    )
}
import React, {useContext, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {ParametersContext} from "../../store/context";
import {TableInEdit} from "../utils/tables";
import {FormCheckbox, FormGroup} from "../../components/form";

function FieldsActiviteApprentissage(props) {
    const { updateParameters } = useContext(ParametersContext);
    const [intervenants, setIntervenants] = useState(props.data.Intervenants);
    const {control, register} = useFormContext();

    useEffect(() => updateParameters({Parameters: "MembrePersonnelID"}), [updateParameters]);

    const {watch, setValue} = useFormContext();
    const watchQ1 = watch("Q1");

    useEffect(() => {
        if (!watchQ1) setValue('ExamQ1', false);
    }, [setValue, watchQ1]);

    return <>
        <FormGroup name={"LibelleAA"} label={"Libelle"}/>
        <FormGroup name={"Ponderation"} label={"Pondération en %"}/>
        <FormCheckbox name={"Q1"} label={"Q1"} edit={true}/>
        <FormCheckbox name={"Q2"} label={"Q2"} edit={true}/>
        <FormCheckbox name={"ExamQ1"} disabled={!watchQ1} label={"Partiel obligatoire"} edit={true}/>

        {[
            {
                "columns": {
                    "Enseignant": i => i.MembrePersonnel.Display,
                    "Responsable": (i) =>
                        <FormCheckbox {...register(`Intervenants[${intervenants.indexOf(i)}][IntervenantResponsable]`)}
                                      control={control}
                                      checked={i.IntervenantResponsable}
                                      edit={true}
                                      onChange={x => {
                                          intervenants.find(f => f.MembrePersonnel.ID === i.MembrePersonnel.ID).IntervenantResponsable = x;
                                          setIntervenants([...intervenants])
                                      }}
                                      name={`Intervenants[${intervenants.indexOf(i)}][IntervenantResponsable]`}
                        />
                },
                "field": "Intervenants",
                "onChange": x => {
                    x.map(m => m['IntervenantResponsable'] = m['IntervenantResponsable'] || false);
                    setIntervenants(x);
                },
                "param": "MembrePersonnelID",
                "emptyValue": "MembrePersonnel"
            },
        ].map((s, k) => {
            return <TableInEdit
                key={k}
                columns={s.columns}
                columnsExport={Object.values(s.columns)}
                fieldName={s.field}
                parameters={s.param}
                name={s.name}
                onChange={s.onChange}
                excludeIDs={s.excludeIDs ?? []}
                emptyValues={s.emptyValue}
                values={props.data[s.field]}/>
        })}
    </>;
}

FieldsActiviteApprentissage.propTypes = {
    data: PropTypes.object.isRequired
}

export default FieldsActiviteApprentissage;
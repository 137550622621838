import React, {useContext, useEffect} from 'react';
import TableSorter from "./TableSorter";
import {EditContext, ParametersContext} from "../store/context";
import * as PropTypes from "prop-types";
import axios from "axios";
import $ from "jquery";

const ScreenTableContext = props => {
    const edit = useContext(EditContext);
    const params = useContext(ParametersContext);
    return <ScreenTable EditPanel={edit} ParametersPanel={params} {...props} />
};

export default ScreenTableContext;

function ScreenTable({columns, content, ...props}) {

    useEffect(() => {
        props.ParametersPanel.updateParameters({
            Parameters: props.Parameters,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        async function fetch() {
            const {data} = await axios.get(props.url);
            if(!props.url.includes("=0")) props.updateState(data);
        }

        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onCreate() {
        $('table').find('.table-secondary').removeClass();
        props.EditPanel.update(true, `Création`, React.cloneElement(props.formNew, {
            data: {IsNew: true},
            onSave: d => onSave(d)
        }));
    }

    async function onSave(d) {
        d = Object.fromEntries(Object.entries(d).filter(([key]) => key.charAt(0) !== '_'));
        const {data} = await axios.post(props.url, d);
        props.updateState(data);

        if (d.IsNew) {
            delete d.IsNew;
            if ($(document).height() > $(window).height())
                setTimeout(() => $('html,body').animate({scrollTop: $(`td:contains('${Object.values(d)[0]}')`).first().offset().top - 180 - $('thead').height()}, 0), 1000);
        }

        props.EditPanel.close();
    }

    function onClick(elm) {
        if (props.formEdit) {
            let selected = content?.find(f => f[f["IDPropertyName"]] === parseInt(elm.currentTarget.dataset.id));
            props.EditPanel.update(true, `Edition`, React.cloneElement(props.formEdit, {
                data: selected,
                onSave: d => onSave(d)
            }));
        }
    }

    return (
        <TableSorter
            addAction={props.formNew ? onCreate.bind(this) : undefined}
            onClick={onClick.bind(this)}
            content={content ?? []}
            columns={columns}
            {...props}
        />
    );
}

ScreenTable.propTypes = {
    columns: PropTypes.object.isRequired,
    content: PropTypes.array,
    Parameters: PropTypes.string,
    url: PropTypes.string.isRequired,
    updateState: PropTypes.func.isRequired,
    formEdit: PropTypes.any,
    formNew: PropTypes.any
}
import React, {useContext} from 'react';
import * as Yup from "yup";
import {TableInEdit} from "../utils/tables";
import {ParametersContext} from "../../store/context";
import {Form, FormCheckbox, FormGroup, FormSelect} from "../../components/form";

FormGroupesParent.schema = Yup.object({
    GroupeParentActiviteApprentissageCode: Yup.string().required().label("Code"),
    GroupeParentActiviteApprentissageNbPlaces: Yup.number().required().integer().label("Nombre de places"),
}).required()

export function FormGroupesParent({data, onSave, AnneeAcademique}) {
    const {parameters} = useContext(ParametersContext);
    
    return (
        <Form onSubmit={d => onSave({...d,  'AnneeAcademiqueID': AnneeAcademique})} defaultValues={data} schema={FormGroupesParent.schema}>
            <FormGroup name={"GroupeParentActiviteApprentissageCode"} label={"Code"} rules={{required: true}}/>
            <FormGroup name={"GroupeParentActiviteApprentissageNbPlaces"} label={"Nombre de places"} rules={{required: true}}/>
            <FormGroup name={"NiveauCoursID"} label={"Niveau de cours lié"} rules={{required: true}} type={FormSelect} options={parameters.p.NiveauCoursID}/>
            <FormGroup name={"CoursLangue2ID"} label={"Langue 2 liée"} rules={{required: true}} type={FormSelect} options={parameters.p.CoursLangue2ID}/>
            <FormCheckbox name={"GroupeParentActiviteApprentissageDiplomeBilingue"} label={"Bilingue"} defaultValue={0} edit={true}/>

            {
                !data.IsNew && <TableInEdit name={"GroupeActiviteApprentissage"}
                                            columns={{"GroupesActiviteApprentissage": x => {
                                                console.log(x);
                                                return x.GroupeActiviteApprentissage.Display
                                            }}}
                                            columnsExport={Object.values({"GroupesActiviteApprentissage": x => x.GroupeActiviteApprentissage.Display})}
                                            fieldName={"LiensGroupeParentActiviteApprentissages"}
                                            reloadParameters={true}
                                            parameters={`GroupeActiviteApprentissageAnneeAcademiqueID-${AnneeAcademique}`}
                                            emptyValues={"LienGroupeParentActiviteApprentissage"}
                                            values={data.LiensGroupeParentActiviteApprentissages}/>
            }
        </Form>
    );
}
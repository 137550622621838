import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-solid-svg-icons";
import {useHotkeys} from "react-hotkeys-hook";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {GlobalContext} from "../../store/context";
import {initialGlobal} from "../../store/initialStates";

function SearchBar() {
    const [isDisplay, setIsDisplay] = useState(false);
    let navigate = useNavigate();
    const { dispatch } = useContext(GlobalContext);

    const inputRef = useRef(null);
    let nbPushKey = 0;

    useEffect(() => {
        inputRef.current.value = "";
        inputRef.current.focus();
    });

    useHotkeys("*", (key) => {
        if (key.key === "Control" && isDisplay) inputRef.current.value = "";

        if (key.key === "Control"){
            if (++nbPushKey >= 2) {
                setIsDisplay(!isDisplay);
                nbPushKey = 0;
            }
            setTimeout(() => (nbPushKey = 0), 250);
        }
    },{ enableOnTags: ['INPUT', "TEXTAREA"], keyup: true, keydown: false }, [nbPushKey, isDisplay, inputRef]);

    const Search = async (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            if (e.target.value === "") return;

            let url = `${process.env.REACT_APP_ROOT}gestionEtudiants/etudiants`;
            const response = await axios.get(`/Search/${e.target.value}`);
            let options = {};

            if (response.data.result.length === 1) {
                url += `/${response.data.result[0].EtudiantID}`;
                options = {state: {EtudiantID: response.data.result[0].EtudiantID }};
            } else {
                dispatch({key: "etudiant", value: initialGlobal.etudiant});
            }

            navigate(url, options);
            dispatch({key: "lastSearch", value: e.target.value});
            dispatch({key: "etudiants", value: response.data.result});
            setIsDisplay(false);
        }
    }

    return (
        <Row id={"SearchBar"}>
            <Col xs={"auto"}>
                <Button variant={"white"} className={"btn-link"} onClick={() => setIsDisplay(!isDisplay)}>
                    <FontAwesomeIcon icon={faSearch}/>
                </Button>
            </Col>
            <Col id="Shortcut" className={isDisplay ? "d-none" : undefined}>
                CTRL² pour rechercher
            </Col>
            <Col className={!isDisplay ? "d-none" : undefined}>
                <input onKeyUp={(e) => Search(e)} className="form-control" autoComplete="off" ref={inputRef} placeholder={"CTRL pour effacer, Enter pour chercher"}/>
            </Col>
        </Row>
    );
}

export default SearchBar;
import React from "react";
import * as PropTypes from "prop-types";
import {Form} from "react-bootstrap";

const MyCheckbox = React.forwardRef((props, ref) => {
    return <>
        <span className={"d-none"}>{props.checked ? 1 : 0}</span>
        <Form.Check ref={ref}
                    className={props.isFilter ? "form-check-filter" : ""}
                    style={props.style}
                    name={props.name}
                    type={props.edit ? (props.isFilter ? "checkbox" : "switch") : "checkbox"}
                    label={props.label}
                    id={props.name}
                    checked={props.checked}
                    disabled={!props.edit || props.disabled}
                    onChange={props.onChange}
                    inline={props.inline || props.isFilter}
        />
    </>;
});

MyCheckbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    edit: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    isFilter: PropTypes.bool,
};

MyCheckbox.defaultProps = {
    edit: false,
    checked: false,
    disabled: false,
    inline: false,
    isFilter: false,
}

export default MyCheckbox;
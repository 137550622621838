import "./static/scss/index.scss";
import {Component} from "react";
import MyRouter from "./routing/router";
import {ToastContainer} from "react-toastify";
import {AppProvider} from "./store/context";
import Pace from "pace-progress"
import './utils/i18n'

Pace.options.eventLag = false;

class App extends Component {
    render() {
        return (
            <>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                    icon={false}
                    theme={"colored"}
                />
                <AppProvider>
                    <MyRouter {...this.props} />
                </AppProvider>
            </>
        );
    }
}

export default App;

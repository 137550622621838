import React from 'react';
import {Form} from "../../components/form";
import {FieldsActiviteApprentissage, FieldsProgrammeEffectif, FieldsUniteEnseignement} from "./index";

const FormProgrammeEffectif = ({data, onSave, onDelete}) => {
    return (
        <Form onSubmit={d => onSave(d)} onDelete={!data.IsNew && data.IsUE ? onDelete : null} defaultValues={data} schema={data.IsNew ? FieldsProgrammeEffectif.schema : (data.IsUE ? FieldsUniteEnseignement.schema : null)}>
            {data.IsNew
                ? <FieldsProgrammeEffectif/>
                : (data.IsUE
                    ? <FieldsUniteEnseignement data={data} />
                    : <FieldsActiviteApprentissage data={data} />)}
        </Form>

    );
};

export default FormProgrammeEffectif;